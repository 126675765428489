import React, { useEffect, useState } from 'react';
import { ReactTabulator } from 'react-tabulator';
import axios from 'axios';
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Paper,
} from '@mui/material';
import { Eye } from 'lucide-react';

const SellerContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'success'>('error');
  const [selectedContact, setSelectedContact] = useState<any | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contact`);
        console.log(response.data.data);
        setContacts(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch contacts.');
        setLoading(false);
        showSnackbar('Erreur lors du chargement des contacts', 'error');
      }
    };

    fetchContacts();
  }, []);

  const handleViewClick = (e: any, cell: any) => {
    e.stopPropagation(); // Empêche la propagation au rowClick
    const row = cell.getRow();
    setSelectedContact(row.getData());
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedContact(null);
  };

  const showSnackbar = (message: string, severity: 'error' | 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleMarkAsProcessed = async (id: string) => {
    console.log(id);
    if (id) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/contact/${id}`,
          { status: true },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        showSnackbar('Contact marqué comme traité', 'success');
        setOpenModal(false);
        setSelectedContact(null);
        // Refetch contacts to update the list
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contact`);
        console.log(response.data.data);
        setContacts(response.data.data);
      } catch (err) {
        showSnackbar('Erreur lors de la mise à jour du statut', 'error');
      }
    }
  };

  const DetailField = ({ label, value }: { label: string; value: any }) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">
        {value || '-'}
      </Typography>
    </Box>
  );

  const moneyFormatter = (cell: any, formatterParams: any, onRendered: any) => {
    const value = cell.getValue();
    const category = cell.getRow().getData().category;
    const symbol = category === 'management' || category === 'Location' ? '€ / mois' : '€';
    return `${value}${symbol}`;
  };
  
  const columns = [
    { title: "Catégorie", field: "category", sorter: "string" as const },
    { title: "Nom", field: "name", sorter: "string" as const , maxWidth: 200},
    { title: "Téléphone", field: "phone", sorter: "string" as const },
    { title: "Email", field: "email", sorter: "string" as const , maxWidth: 200},
    { title: "Objet", field: "object", sorter: "string" as const , maxWidth: 200},
    { title: "Message", field: "message", sorter: "string" as const, maxWidth: 200 },
    { title: "Type", field: "type", sorter: "string" as const },
    { title: "Commune", field: "commune", sorter: "string" as const },
    { title: "Rue", field: "street", sorter: "string" as const , maxWidth: 200},
    { title: "Quartier", field: "neighborhood", sorter: "string" as const },
    { title: "Superficie", field: "area", sorter: "number" as const },
    { 
      title: "Estimation", 
      field: "estimate", 
      sorter: "number" as const, 
      formatter: moneyFormatter, 
      formatterParams: { symbolAfter: true, symbol: "€" }, 
      sorterParams: { alignEmptyValues: "bottom" as const }, 
    },
    { 
      title: "Status", 
      field: "status", 
      sorter: "boolean" as const, 
      maxWidth: 200,
      formatter: (cell: any) => (cell.getValue() ? '<span style="color: green;">Traité</span>' : 'A traité'),
    },
    {
      title: "Action",
      headerSort: false,
      formatter: () => {
        return '<button class="view-btn" style="background-color: #1976d2; color: white; border: none; padding: 6px 16px; border-radius: 4px; cursor: pointer; display: flex; align-items: center; gap: 4px;"><span>Voir</span></button>';
      },
      cellClick: handleViewClick,
      sorter: "string" as const, // Update the type of sorter to "string" as const
    }
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, color: "#3f51b5", textAlign: { xs: "center", md: "left" } }}
      >
        Liste des contacts et demandes
      </Typography>

      <ReactTabulator
        data={contacts}
        columns={columns}
        options={{
          layout: "fitDataTable",
          responsiveLayout: "collapse",
          pagination: "local",
          locale:"fr-fr",
          langs:{
            "fr-fr": {
              "pagination": {
              "page_size": "Taille de page",
              "first": "Premier",
              "first_title": "Première Page",
              "last": "Dernier",
              "last_title": "Dernière Page",
              "prev": "Précédent",
              "prev_title": "Page Précédente",
              "next": "Suivant",
              "next_title": "Page Suivante",
              "all": "Tous"
              }
            }
          },
          paginationSize: 10,
          paginationSizeSelector: [5, 10, 25],
          movableColumns: false,
          placeholder: "Aucun contact trouvé",
          maxHeight: "70vh",
          virtualDom: true,
          renderVertical: 'basic',
          renderHorizontal: 'virtual',
          resizableColumns: false,
          responsiveLayoutCollapseStartOpen: false,
          columnDefaults: {
            resizable: false,
          }
        }}
      />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Détails du contact
        </DialogTitle>
        <DialogContent>
          {selectedContact && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Informations du contact
                  </Typography>
                  <DetailField 
                    label="Nom" 
                    value={selectedContact.name} 
                  />
                  <DetailField 
                    label="Email" 
                    value={selectedContact.email} 
                  />
                  <DetailField 
                    label="Téléphone" 
                    value={selectedContact.phone} 
                  />
                  <DetailField 
                    label="Objet" 
                    value={selectedContact.object} 
                  />
                  <DetailField 
                    label="Message" 
                    value={selectedContact.message} 
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Détails de la propriété
                  </Typography>
                  <DetailField 
                    label="Type" 
                    value={selectedContact.type} 
                  />
                  <DetailField 
                    label="Commune" 
                    value={selectedContact.commune} 
                  />
                  <DetailField 
                    label="Rue" 
                    value={selectedContact.street} 
                  />
                  <DetailField 
                    label="Quartier" 
                    value={selectedContact.neighborhood} 
                  />
                  <DetailField 
                    label="Superficie" 
                    value={selectedContact.area} 
                  />
                  <DetailField 
                    label="Estimation" 
                    value={(selectedContact.category === 'Location' || selectedContact.category === 'management')  ? selectedContact.estimate + '€ / mois' : selectedContact.estimate + '€'} 
                  />
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Fermer
          </Button>
          {selectedContact && !selectedContact.status && (
            <Button onClick={() => handleMarkAsProcessed(selectedContact.id)} variant="contained" color="success">
              Traité
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity} 
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SellerContacts;