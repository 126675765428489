import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createProperty, updateProperty, uploadImages } from "../services/property";
import { Property, PropertyCreateInput, PropertyImageUpload, UpdatePropertyInput } from "../types/property.types";



export const useCreatePropertyMutation = () => {
    const navigate = useNavigate();

    return useMutation<Property, Error, PropertyCreateInput>({
        mutationFn: createProperty,
        onSuccess: (data) => {
            navigate("/property");
        },
        onError: (error) => {
            console.error("creation failed:", error);
        },
    });
};

export const useUploadPhotosPropertyMutation = () => {
    return useMutation<Property, Error, PropertyImageUpload>({
        mutationFn: uploadImages,
        onSuccess: () =>{},
        onError: (error) => {
            console.error("creation failed:", error);
        },
    });
};

export const useUpdatePropertyMutation = () => {
    return useMutation<Property, Error, any>({
        mutationFn: updateProperty,
        onSuccess: () =>{},
        onError: (error) => {
            console.error("creation failed:", error);
        },
    });
}