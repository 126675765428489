import React, { useState, useEffect } from "react";
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/css/tabulator.min.css';
import {
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Rating,
  Avatar,
  Grid,
  Divider,
  AlertColor
} from "@mui/material";
import axios from "axios";
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import { useParams } from "react-router-dom";

const RatingList = () => {
  const [ratings, setRatings] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isLoading, setIsLoading] = useState(true);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedRating, setSelectedRating] = useState<any>(null);
  const { id } = useParams();

  const fetchRatings = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/rating/${id}`);
      setRatings(response.data.data);
    } catch (error) {
      showSnackbar('Erreur lors du chargement des évaluations', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRatings();
  }, []);

  const showSnackbar = (message: any, severity: any) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleDeleteClick = (id: any) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDetailModalClose = () => {
    setDetailModalOpen(false);
    setSelectedRating(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/rating/${selectedId}`);
      showSnackbar('Évaluation supprimée avec succès', 'success');
      await fetchRatings();
    } catch (error) {
      showSnackbar('Erreur lors de la suppression', 'error');
    } finally {
      setOpen(false);
    }
  };

  const handlePublishToggle = async (id: any, currentStatus: any) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/rating/validate/${id}/status/${!currentStatus}`
      );
      showSnackbar(
        `Évaluation ${currentStatus ? 'dépubliée' : 'publiée'} avec succès`,
        'success'
      );
      await fetchRatings();
    } catch (error) {
      showSnackbar("Erreur lors de la modification du statut", 'error');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const showDetails = (rating: any) => {
    setSelectedRating(rating);
    setDetailModalOpen(true);
  };

  const columns = [
    {
      title: "Date",
      field: "createdAt",
      formatter: (cell: any) => {
        return new Date(cell.getValue()).toLocaleDateString();
      },
      width: 140
    },
    {
      title: "Note",
      field: "rate",
      formatter: (cell: any) => {
        const rating = cell.getValue();
        return Array(rating)
          .fill(0)
          .map((_, i) => '⭐')
          .join('');
      },
      width: 140
    },
    {
      title: "Commentaire",
      field: "comment",
      formatter: (cell: any) => {
        const comment = cell.getValue();
        return comment && comment.length > 100 ? comment.substring(0, 100) + '...' : comment;
      }
    },
    {
        title: "Agent",
        field: 'user', 
        resizable: false, 
        formatter: (cell: any) => `${cell.getValue()?.firstName} ${cell.getValue()?.lastName}`,
      },
    {
      title: "Statut",
      field: "published",
      formatter: (cell: any) => cell.getValue() ? "Publié" : "Non publié",
      width: 120
    },
    {
      title: "Actions",
      field: "actions",
      responsive: 0,
      headerSort: false,
      width: 300,
      formatter: function(cell: any) {
        const row = cell.getRow();
        const data = row.getData();
        const userRole = localStorage.getItem("role");
        return `
          <div style="display: flex; gap: 8px;">
            <button 
              class="details-btn" 
              style="padding: 6px 12px; background-color: #2196f3; color: white; border: none; border-radius: 4px; cursor: pointer;"
              data-id="${data.id}"
            >
              Voir détails
            </button>
            ${userRole !== 'agent' ? `
            <button 
              class="publish-btn"
              style="padding: 6px 12px; background-color: ${data.published ? '#ff9800' : '#4caf50'}; color: white; border: none; border-radius: 4px; cursor: pointer;"
              data-id="${data.id}" 
              data-published="${data.published}"
            >
              ${data.published ? 'Dépublier' : 'Publier'}
            </button>
            <button 
              class="delete-btn"
              style="padding: 6px 12px; background-color: #f44336; color: white; border: none; border-radius: 4px; cursor: pointer;"
              data-id="${data.id}"
            >
              Supprimer
            </button>
            ` : ''}
          </div>
        `;
      },
      cellClick: function(e: any, cell: any) {
        const element = e.target;
        const row = cell.getRow();
        const data = row.getData();
        console.log(data);
        
        if (element.classList.contains('details-btn')) {
          showDetails(data);
        } else if (element.classList.contains('publish-btn')) {
          handlePublishToggle(data.id, data.published);
        } else if (element.classList.contains('delete-btn')) {
          handleDeleteClick(data.id);
        }
      }
    }
  ];

  return (
    <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, color: "#3f51b5", textAlign: { xs: "center", md: "left" } }}
      >
        Liste des évaluations
      </Typography>

      <ReactTabulator
        data={ratings}
        columns={columns}
        options={{
          layout: "fitDataTable",
          responsiveLayout: 'collapse',
          pagination: "local",
          locale:"fr-fr",
          langs:{
            "fr-fr": {
              "pagination": {
              "page_size": "Taille de page",
              "first": "Premier",
              "first_title": "Première Page",
              "last": "Dernier",
              "last_title": "Dernière Page",
              "prev": "Précédent",
              "prev_title": "Page Précédente",
              "next": "Suivant",
              "next_title": "Page Suivante",
              "all": "Tous"
              }
            }
          },
          paginationSize: 10,
          paginationSizeSelector: [5, 10, 25],
          placeholder: "Aucune évaluation trouvée",
          maxHeight: "70vh",
          initialSort: [{ column: "createdAt", dir: "desc" }],
        }}
      />

      {/* Modal de suppression */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmer la suppression
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer cette évaluation ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
        <Dialog
          open={detailModalOpen}
          onClose={handleDetailModalClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Détails de l'évaluation
            {selectedRating && (
          <Typography variant="subtitle2" color="text.secondary">
            {new Date(selectedRating.createdAt).toLocaleDateString()}
          </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            {selectedRating && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
            Note
              </Typography>
              <Rating
            value={selectedRating.rate}
            readOnly
            icon={<StarIcon fontSize="inherit" />}
            emptyIcon={<StarIcon fontSize="inherit" />}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
            Commentaire
              </Typography>
              <Typography variant="body1">
            {selectedRating.comment}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
            Utilisateur concerné
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar>
              {selectedRating.user.avatar ? (
                <img src={`${process.env.REACT_APP_API_URL}/files/avatars/${selectedRating.user.avatar}`} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <PersonIcon />
              )}
            </Avatar>
            <Box>
              <Typography>
                {selectedRating.user.firstName} {selectedRating.user.lastName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {selectedRating.user.email}
              </Typography>
            </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
            Email de l'évaluateur
              </Typography>
              <Typography>
            {selectedRating.email}
              </Typography>
            </Grid>
          </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDetailModalClose} color="primary">
          Fermer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert 
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity as AlertColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RatingList;