import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  useTheme,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  NavigateNext as NextIcon,
  NavigateBefore as PrevIcon
} from '@mui/icons-material';

interface ImageViewerProps {
  images: string[];
  open: boolean;
  onClose: () => void;
  initialIndex?: number;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  images,
  open,
  onClose,
  initialIndex = 0
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const theme = useTheme();

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handlePrevious();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      onKeyDown={handleKeyDown}
    >
      <DialogContent 
        sx={{ 
          p: 0, 
          position: 'relative',
          bgcolor: 'black',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.7)',
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Navigation buttons */}
        <IconButton
          onClick={handlePrevious}
          sx={{
            position: 'absolute',
            left: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.7)',
            }
          }}
        >
          <PrevIcon />
        </IconButton>

        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.7)',
            }
          }}
        >
          <NextIcon />
        </IconButton>

        {/* Main image */}
        <Box
          component="img"
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          sx={{
            maxHeight: '80vh',
            maxWidth: '100%',
            objectFit: 'contain'
          }}
        />

        {/* Thumbnails */}
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: 2,
            p: 1
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="white">
              {currentIndex + 1} / {images.length}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                overflowX: 'auto',
                maxWidth: '60vw',
                '&::-webkit-scrollbar': {
                  height: 6
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[500],
                  borderRadius: 3
                }
              }}
            >
              {images.map((img, index) => (
                <Box
                  key={index}
                  component="img"
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setCurrentIndex(index)}
                  sx={{
                    height: 60,
                    width: 60,
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: 1,
                    opacity: currentIndex === index ? 1 : 0.6,
                    transition: 'opacity 0.2s',
                    border: currentIndex === index ? `2px solid ${theme.palette.primary.main}` : 'none',
                    '&:hover': {
                      opacity: 1
                    }
                  }}
                />
              ))}
            </Box>
          </Stack>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewer;