import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Icon,
  Grid,
} from "@mui/material";

import { styled } from "@mui/system";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ContactsIcon from "@mui/icons-material/Contacts";
import SellIcon from "@mui/icons-material/Sell";
import { colors } from "../utils/constant";
import axios from "axios";
import toast from "react-hot-toast";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
}));

const StatCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const IconWrapper = styled("div")(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 65,
  width: 65,
  borderRadius: "50%",
  backgroundColor: color,
  marginBottom: theme.spacing(2),
}));

const Dashboard = () => {
  const [stats, setStats] = useState({
    users: 0,
    rentAnnonce: 0,
    soldAnnonce: 0,
    rented: 0,
    solded: 0,
    message: 0,
    alert: 0,
    contact: 0,
  });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/data`); // Remplace l'URL par celle de ton API
        const data = response.data.data;
        console.log(data);

        setStats({
          users: data.users,
          rentAnnonce: data.rentAnnonce,
          soldAnnonce: data.soldAnnonce,
          rented: data.rented,
          solded: data.solded,
          message: data.message,
          alert: data.alert,
          contact: data.contact,
        });

      } catch (error) {
        toast.error("Erreur lors de la récupération des données du tableau de bord");
      }
    };

    fetchData();
  }, []);

  const statsList = [
    { title: "Utilisateurs", value: stats.users, icon: PeopleIcon, color: "#bbdefb" },
    { title: "Annonces location", value: stats.rentAnnonce, icon: HomeIcon, color: "#ffcccb" },
    { title: "Annonces ventes", value: stats.soldAnnonce, icon: LocalOfferIcon, color: "#c8e6c9" },
    { title: "Biens loués", value: stats.rented, icon: BusinessIcon, color: "#e1bee7" },
    { title: "Messages clients", value: stats.message, icon: EmailIcon, color: "#fff9c4" },
    { title: "Alertes", value: stats.alert, icon: NotificationsActiveIcon, color: "#f8bbd0" },
    { title: "Contacts vendeurs", value: stats.contact, icon: ContactsIcon, color: "#c5cae9" },
    { title: "Biens Vendus", value: stats.solded, icon: SellIcon, color: "#b2dfdb" },
  ];
  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginBottom: 4, textAlign: "center" }}
      >
        Tableau de bord
      </Typography>
      <Grid container spacing={3}>
        {statsList.map((stat: any, index: any) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatCard>
              <CardContent>
                <IconWrapper color={stat.color}>
                  <Icon
                    component={stat.icon}
                    sx={{ fontSize: 40, color: "rgba(0, 0, 0, 0.54)" }}
                  />
                </IconWrapper>
                <Typography variant="h5" component="div">
                  {stat.value}
                </Typography>
                <Typography color="text.secondary">{stat.title}</Typography>
              </CardContent>
            </StatCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;