import React, { FC, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Chip,
  Card,
  CardContent,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Paper,
  Divider,
  IconButton,
  Tooltip,
  LinearProgress
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import {
  Send as SendIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Public as PublicIcon,
  UnpublishedOutlined as UnpublishedIcon,
  Home as HomeIcon,
  Square as SquareIcon,
  BathtubOutlined as BathIcon,
  LocalParking as ParkingIcon,
  LocationOn as LocationIcon,
  Bed as BedIcon
} from "@mui/icons-material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import useQueryProperty from "../queries/useQueryProperty";
import { updateProperty } from "../services/property";
import { useUpdatePropertyMutation } from "../mutations/usePropertyMutation";
import axios from "axios";
import { toast } from "react-hot-toast";
import ImageViewer from "./ImageViewer";

const defaultAvatar = "https://via.placeholder.com/150";
const linkAvatar = `${process.env.REACT_APP_API_URL}/files/avatars/`;

const customMarkerIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
interface stateProp {
  icon: any;
  value: any;
  label: any;
}

const PropertyStat: FC<stateProp> = ({ icon, value, label }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1,
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 2,
    boxShadow: 1
  }}>
    {icon}
    <Box>
      <Typography variant="h6" component="div">{value}</Typography>
      <Typography color="text.secondary" variant="body2">{label}</Typography>
    </Box>
  </Box>
);
const PhotoGallery: FC<any> = ({ photos }) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleImageClick = (index: number) => {
    setSelectedIndex(index);
    setViewerOpen(true);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ my: 2 }}>
        {photos.length > 0 ? (
          photos.map((photo: string, index: number) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  overflow: 'hidden',
                  transition: 'transform 0.3s',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={photo}
                  alt={`property-${index}`}
                  style={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "8px"
                  }}
                />
              </Paper>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="text.secondary">
            Aucune photo disponible
          </Typography>
        )}
      </Grid>

      <ImageViewer
        images={photos}
        open={viewerOpen}
        onClose={() => setViewerOpen(false)}
        initialIndex={selectedIndex}
      />
    </>
  );
};
// const PhotoGallery: FC<any>  = ({ photos }) => {
//   return (
//     <Grid container spacing={2} sx={{ my: 2 }}>
//       {photos.length > 0 ? (
//         photos.map((photo:any, index:any) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Paper
//               elevation={3}
//               sx={{
//                 overflow: 'hidden',
//                 transition: 'transform 0.3s',
//                 '&:hover': {
//                   transform: 'scale(1.02)',
//                 },
//               }}
//             >
//               <img
//                 src={photo}
//                 alt={`property-${index}`}
//                 style={{
//                   width: "100%",
//                   height: "250px",
//                   objectFit: "cover",
//                   borderRadius: "8px"
//                 }}
//               />
//             </Paper>
//           </Grid>
//         ))
//       ) : (
//         <Typography variant="body1" color="text.secondary">Aucune photo disponible</Typography>
//       )}
//     </Grid>
//   );
// };

const PropertyDetails = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getOneProperty } = useQueryProperty();
  const { data: property, isLoading, refetch } = getOneProperty(id);
  const updatePropertyMutation = useUpdatePropertyMutation();

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (!property) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary">
          Aucune propriété trouvée
        </Typography>
      </Box>
    );
  }

  const {
    reference,
    type,
    category,
    price,
    area,
    rooms,
    bathRoom,
    bedRoom,
    parking,
    description,
    characteristic,
    city,
    street,
    number,
    title,
    createdAt,
    photos,
    agent,
    district,
    commune,
    published_at,
    available
  } = property;

  const images = photos.map(
    (photo) => `${process.env.REACT_APP_API_URL}/files/uploads/${photo}`
  );

  const position: any = [district.latitude, district.longitude];

  const handlePublishButton = async () => {
    try {
      const pubStatus = published_at === null ? new Date() : null;
      await updateProperty({ id, published_at: pubStatus });
      toast.success(published_at === null ? 'Propriété publiée' : 'Propriété dépubliée');
      navigate("/property");
    } catch (error) {
      toast.error('Une erreur est survenue');
    }
    refetch();
  };

  const handleSolded = async () => {
    try {
      await updateProperty({ id, available: false });
      toast.success(category === 'Vente' ? 'Propriété marquée comme vendue' : 'Propriété marquée comme louée');
      navigate("/property");
    } catch (error) {
      toast.error('Une erreur est survenue');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/property/${id}`);
      toast.success('Propriété supprimée avec succès');
      navigate("/property");
    } catch (error) {
      toast.error('Erreur lors de la suppression');
    }
    setOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header Actions */}
      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        spacing={2} 
        sx={{ mb: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h1" fontWeight="bold">
          {title}
        </Typography>

        <Stack direction="row" spacing={2}>
          <Tooltip title={published_at === null ? "Publier" : "Dépublier"}>
            <Button
              variant={published_at === null ? "contained" : "outlined"}
              onClick={handlePublishButton}
              color={published_at === null ? "success" : "error"}
              startIcon={published_at === null ? <PublicIcon /> : <UnpublishedIcon />}
            >
              {published_at === null ? "Publier" : "Dépublier"}
            </Button>
          </Tooltip>

          {published_at !== null && (
            <Tooltip title={available ? `Marquer comme ${category === 'Achat' ? 'vendu' : 'loué'}` : `${category === 'Achat' ? 'Vendu' : 'Loué'}`}>
              <Button
                variant={available ? "contained" : "outlined"}
                onClick={handleSolded}
                disabled={!available}
                startIcon={available ? <SendIcon /> : <CheckIcon />}
              >
                {category === 'Achat' ? (available ? 'Marquer comme vendu' : 'Vendu') : (available ? 'Marquer comme loué' : 'Loué')}
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Modifier">
            <IconButton onClick={() => navigate(`/property/update/${id}`)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Supprimer">
            <IconButton onClick={() => setOpen(true)} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {/* Property Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <PropertyStat 
            icon={<HomeIcon color="primary" />} 
            value={rooms}
            label="Pièces"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PropertyStat 
            icon={<BedIcon color="primary" />} 
            value={bedRoom  || 0}
            label="Chambres"
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <PropertyStat 
            icon={<SquareIcon color="primary" />} 
            value={`${area} m²`}
            label="Surface"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PropertyStat 
            icon={<BathIcon color="primary" />} 
            value={bathRoom || 0}
            label="Salles de bain"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PropertyStat 
            icon={<ParkingIcon color="primary" />} 
            value={parking  || 0}
            label="Places de parking"
          />
        </Grid>
      </Grid>

      {/* Main Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Description</Typography>
              <Typography paragraph>{description}</Typography>

              <Divider sx={{ my: 3 }} />

              <Typography variant="h6" gutterBottom>Caractéristiques</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {characteristic.map((char, index) => (
                  <Chip 
                    key={index} 
                    label={char} 
                    color="primary" 
                    variant="outlined"
                    sx={{ borderRadius: 2 }}
                  />
                ))}
              </Box>

              <Divider sx={{ my: 3 }} />

              <Typography variant="h6" gutterBottom>Photos</Typography>
              <PhotoGallery photos={images} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            {/* Price Card */}
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h4" color="primary" gutterBottom>
                  {price.toLocaleString()} €
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {category} - Réf: {reference}
                </Typography>
              </CardContent>
            </Card>

            {/* Location Card */}
            <Card elevation={3}>
              <CardContent>
                <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                  <LocationIcon color="primary" />
                  <Typography variant="h6">Localisation</Typography>
                </Stack>
                <Typography gutterBottom>
                {number} {street}  {district.name}, {commune.name} {city}
                </Typography>
                <Box sx={{ mt: 2, height: '300px', borderRadius: 2, overflow: 'hidden' }}>
                  <MapContainer
                    center={position}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    />
                    <Marker position={position} icon={customMarkerIcon}>
                      <Popup>
                        {district.name}
                      </Popup>
                    </Marker>
                  </MapContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Agent Card */}
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Agent</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    alt={agent.firstName}
                    src={linkAvatar + agent.avatar || defaultAvatar}
                    sx={{ width: 64, height: 64 }}
                  />
                  <Box>
                    <Typography variant="subtitle1">{agent.firstName + " " + agent.lastName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {agent.email}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            {/* Details Card */}
            <Card elevation={3}>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Créé le {dayjs(createdAt).format("DD/MM/YYYY")}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      {/* Delete Dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmer la suppression
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette propriété ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default PropertyDetails;