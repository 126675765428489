import React, { useState } from "react";
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/css/tabulator.min.css'; // Import CSS for Tabulator
import {
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Rating,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import useQueryUser from "../queries/useQueryUser";
import { SearchParams } from "../services/property";
import Loader from "../components/common/Loader";
import { User } from "../types/property.types";
import axios from "axios";

const NonCustomerUserListing = () => {
  const [open, setOpen] = useState(false);
  const [deletable, setDeletable] = useState(true);
  const [id, setId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarSupp, setOpenSnackbarSupp] = useState(false);

  const [openComments, setOpenComments] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const { getAllUsers } = useQueryUser();
  const navigate = useNavigate();
  const variables: SearchParams = {};
  const { data: users, error, isLoading, refetch } = getAllUsers(variables);
  const userRole = localStorage.getItem("role");

  const calculateAverageRating = (ratings: any) => {
    if (!ratings || ratings.length === 0) return 0;
    const sum = ratings.reduce((acc: any, curr: any) => acc + curr.rate, 0);
    return sum / ratings.length;
  };

  const handleViewComments = (user: any) => {
    setSelectedUser(user);
    setOpenComments(true);
  };

  const handleDeleteClick = async (idUser: string) => {
    if (userRole === "agent") {
      setOpenSnackbarSupp(true);
      return;
    }

    setId(idUser);
    const check = await axios.get(`${process.env.REACT_APP_API_URL}/property/can-delete-agent/${idUser}`);
    const able = check.data.data.deletable;
    setDeletable(able);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`);

      if (response.data) {
        console.log("Propriété supprimée avec succès");
        await refetch(); // Fetch all users again after delete
      } else {
        console.error("Erreur lors de la suppression de la propriété");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    } finally {
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (userRole === "agent") {
      setOpenSnackbar(true);
      return;
    }
    navigate("/agents/add");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbarSupp = () => {
    setOpenSnackbarSupp(false);
  };

  if (isLoading) return <Loader />;

  // Filter users to exclude those with the role 'customer'
  const filteredUsers = users.filter((user: User) => user.roles.toLowerCase() !== 'customer');

  // Configuration des colonnes pour Tabulator
  const columns = [
    {
      title: "Date d'inscription", field: "createdAt", formatter: (cell: any) => {
        return new Date(cell.getValue()).toLocaleDateString();
      }, formatterParams: { outputFormat: "DD/MM/YYYY" }
    },
    { title: "Téléphone", field: "phone" },
    { title: "Nom", field: "lastName", resizable: false, responsive: 0 , maxWidth: 200},
    { title: "Prénom", field: "firstName", maxWidth: 200 },
    { title: "Email", field: "email", maxWidth: 200 },
    {
      title: "Role",
      field: "roles",
      formatter: (cell: any) => cell.getValue().toUpperCase()  // Affiche le rôle en majuscule
    },
    {
      title: "Note moyenne",
      field: "rating",
      formatter: (cell: any) => {
      const rating = cell.getValue();
      const average = calculateAverageRating(rating);
      return Array(Math.floor(average))
        .fill(0)
        .map((_, i) => '⭐')
        .join('');
      },
    },
    {
      title: 'Commentaires',
      formatter: (cell: any) => {
        const row = cell.getRow().getData();
        const count = row.rating ? row.rating.length : 0;
        return `<button class="comment-btn">Voir (${count})</button>`;
      },
      cellClick: (e: any, cell: any) => {
        const idUser = cell.getRow().getData().id;
        navigate(`/agents/rating/${idUser}`);
      },
      // width: 120,
    },
    // { title: "Confirmé", field: "confirmed", formatter: (cell: any) => (cell.getValue() ? "Oui" : "Non") },
    {
      title: 'Action',
      responsive: 0,
      resizable: false,
      formatter: (cell: any, formatterParams: any, onRendered: any) => {
        return `<button class="delete-btn">Supprimer</button>`;
      },
      cellClick: (e: any, cell: any) => {
        const idUser = cell.getRow().getData().id;
        handleDeleteClick(idUser);
      },
      headerSort: false,
      width: 100,
    },
  ];

  // Add "Voir" column if userRole is not "agent" or "admin"
  if (userRole !== "agent") {
    columns.push({
      title: '',
      responsive: 0,
      resizable: false,
      formatter: (cell: any) => {
        return `<button class="edit-btn">Modifier</button>`;
      },
      cellClick: (e: any, cell: any) => {
        const idUser = cell.getRow().getData().id;
        navigate(`/agents/${idUser}`);
      },
      headerSort: false,
      width: 100,
    });
  }

  return (
    <>
      <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
        <Typography
          variant="h5"
          sx={{ mb: 2, color: "#3f51b5", textAlign: { xs: "center", md: "left" } }}
        >
          Liste des agents et admins
        </Typography>

        <ReactTabulator
          data={filteredUsers}
          columns={columns}
          options={{
            layout: "fitDataTable",
            responsiveLayout: "collapse",
            pagination: "local",
              locale:"fr-fr",
            langs:{
              "fr-fr": {
                "pagination": {
                "page_size": "Taille de page",
                "first": "Premier",
                "first_title": "Première Page",
                "last": "Dernier",
                "last_title": "Dernière Page",
                "prev": "Précédent",
                "prev_title": "Page Précédente",
                "next": "Suivant",
                "next_title": "Page Suivante",
                "all": "Tous"
                }
              }
            },
            paginationSize: 10,
            paginationSizeSelector: [5, 10, 25],
            movableColumns: true,
            placeholder: "Aucun agent trouvé",
            maxHeight: "50vh",
            initialSort: [
              { column: "createdAt", dir: "desc" }
            ],
            rowFormatter: function (row: any) {
              const element = row.getElement();
              element.style.borderBottom = "1px solid #ddd";
            },
            virtualDom: true,
            renderVertical: 'basic',
            renderHorizontal: 'virtual',
            resizableColumns: false,
            responsiveLayoutCollapseStartOpen: false,
            columnDefaults: {
              resizable: false,
            },
          }}
        />

        <Button
          onClick={handleClick}
          variant="contained"
          color="primary"
          sx={{ mt: 2, width: { xs: "100%", sm: "auto" } }}
        >
          Ajouter un utilisateur
        </Button>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
            Vous n'avez pas l'autorisation d'ajouter un utilisateur.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackbarSupp}
          autoHideDuration={6000}
          onClose={handleCloseSnackbarSupp}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbarSupp} severity="error" sx={{ width: "100%" }}>
            Vous n'avez pas l'autorisation de supprimer un utilisateur.
          </Alert>
        </Snackbar>
      </Box>

      {/* Modal des commentaires */}
      <Dialog
          open={openComments}
          onClose={() => setOpenComments(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {selectedUser && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h6">
                  Avis pour {selectedUser.firstName} {selectedUser.lastName}
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body2">Note moyenne:</Typography>
                  <Rating
                    value={calculateAverageRating(selectedUser.rating)}
                    readOnly
                    precision={0.5}
                  />
                  <Typography variant="body2">
                    ({calculateAverageRating(selectedUser.rating).toFixed(1)})
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogTitle>
          <DialogContent>
            {selectedUser && (
              <List>
                {selectedUser.rating && selectedUser.rating.length > 0 ? (
                  selectedUser.rating.map((rating: any, index: any) => (
                    <React.Fragment key={rating.id}>
                      <ListItem>
                        <Box width="100%">
                          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="subtitle2" color="text.secondary">
                              {new Date(rating.createdAt).toLocaleDateString()} - {rating.email}
                            </Typography>
                            <Rating value={rating.rate} readOnly size="small" />
                          </Box>
                          <ListItemText
                            primary={rating.comment}
                            sx={{ whiteSpace: 'pre-wrap' }}
                          />
                        </Box>
                      </ListItem>
                      {index < selectedUser.rating.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary" textAlign="center" py={2}>
                    Aucun avis disponible
                  </Typography>
                )}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenComments(false)} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmer la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              deletable ? 'Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.' :
               'On ne peut pas supprimer cet utilisateur car il est assigné à un ou plusieurs propriété'
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={handleConfirmDelete}
            disabled={!deletable}
            color="warning" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NonCustomerUserListing;