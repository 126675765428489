import { AxiosResponse } from "axios";
import api from "./api";
import { Property, PropertyCreateInput, PropertyImageUpload, UpdatePropertyInput } from "../types/property.types";

export interface SearchParams {
  page?: string;
  perPage?: string;
  orderBy?: string;
  where?: string;
}

export const getProperties = async (
  searchParams?: SearchParams
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get("/property/admin/all", {
      params: searchParams,
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getProperty = async (id?: string): Promise<Property> => {
  try {
    const response: AxiosResponse = await api.get(`/property/${id}`);

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const createProperty = async (property: PropertyCreateInput): Promise<Property> => {
  try {
    const response: AxiosResponse = await api.post("/property", property);

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateProperty = async ( property: UpdatePropertyInput): Promise<Property> => {
  try {
    const response: AxiosResponse = await api.put(`/property/${property.id}`, {published_at: property.published_at, available: property.available});

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const uploadImages = async (photos: PropertyImageUpload): Promise<Property> => {
  const { propertyId, formData } = photos;
  try {
    const response: AxiosResponse = await api.post(`/property/${propertyId}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export const getCommunes = async () => {
  try {
    const response: AxiosResponse = await api.get('/contact/communes')
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const getAgents = async () => {
  try {
    const response: AxiosResponse = await api.get('/users?where=roles%3Aagent');
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
