import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MonCompte = () => {
  const [userData, setUserData] = useState({
    id: '',
    phone: '',
    firstName: '',
    lastName: '',
    roles: '',
    email: '',
    avatar: '',
    createdAt: '',
    updatedAt: '',
    confirmed: false,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        console.log(data.data);
        setUserData(data.data);
        if (data.data.avatar) {
          setImagePreview(`${process.env.REACT_APP_API_URL}/files/avatars/${data.data.avatar}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
        }),
      });

      if (imageFile) {
        const formData = new FormData();
        formData.append('file', imageFile);

        await fetch(`${process.env.REACT_APP_API_URL}/users/avatar`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        });
      }

      setSnackbarMessage('Profile modifié avec success');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Error updating profile: ' + error);
      setSnackbarSeverity('error');
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword.length < 6) {
      setSnackbarMessage('Le nouveau mot de passe doit contenir au moins 6 caractères.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Mot de passe modifié avec succès');
        setSnackbarSeverity('success');
      } else {
        const errorData = await response.json();
        setSnackbarMessage('Erreur: ' + errorData.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Error updating password: ' + error);
      setSnackbarSeverity('error');
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
      setOpenPasswordDialog(false);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 500, mx: 'auto', textAlign: 'center' }}>
      <Typography variant="h5" sx={{ mb: 2, color: '#3f51b5' }}>
        Modifier mon profil
      </Typography>
      <Box component="form" onSubmit={handleFormSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            id="profile-picture-upload"
            onChange={handleImageChange}
          />
          <label htmlFor="profile-picture-upload">
            <Avatar
              src={imagePreview || undefined}
              sx={{ width: 100, height: 100, cursor: 'pointer' }}
            />
          </label>
        </Box>
        <TextField
          fullWidth
          label="Prénom"
          name="firstName"
          value={userData.firstName}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          fullWidth
          label="Nom"
          name="lastName"
          value={userData.lastName}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          required
        />
        <PhoneInput
          country={'cd'} // RDC (Congo) country code
          value={userData.phone}
          onChange={(phone) => setUserData((prevData) => ({ ...prevData, phone }))}
          inputStyle={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
          containerStyle={{ width: '100%' }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={userData.email}
          margin="normal"
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          label="Rôle"
          name="roles"
          value={userData.roles}
          margin="normal"
          variant="outlined"
          disabled
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, mr: 1 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Mettre à jour le profil'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={() => setOpenPasswordDialog(true)}
        >
          Modifier mon mot de passe
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
        <DialogTitle>Modifier le mot de passe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer votre mot de passe actuel et le nouveau mot de passe.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Mot de passe actuel"
            type={showCurrentPassword ? 'text' : 'password'}
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            label="Nouveau mot de passe"
            type={showNewPassword ? 'text' : 'password'}
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPasswordDialog(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handlePasswordChange} color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Modifier'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MonCompte;