import React, { useState } from "react";
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/css/tabulator.min.css'; // Import CSS for Tabulator
import {
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Avatar
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

import { useNavigate } from "react-router-dom";
import useQueryUser from "../queries/useQueryUser";
import { SearchParams } from "../services/property";
import Loader from "../components/common/Loader";
import { User } from "../types/property.types";
import axios from "axios";

const UserListing = () => {
  const { getAllUsers } = useQueryUser();
  const navigate = useNavigate();
  const variables: SearchParams = {};
  const { data: users, error, isLoading, refetch } = getAllUsers(variables);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const userRole = localStorage.getItem("role");

  if (isLoading) return <Loader />;

  const filteredUsers = users.filter((user: User) => user.roles.toLowerCase() === 'customer');

  const handleOpenDialog = (user: User) => {
    setSelectedUser(user);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setIsDialogOpen(false);
  };

  const handleDeactivateUser = async (userId: string, userStatus: boolean) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/status/${userStatus}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setSnackbarMessage(userStatus ? "Utilisateur réactivé avec succès" : "Utilisateur désactivé avec succès");
      setSnackbarOpen(true);
      handleCloseDialog();
      refetch(); // Refetch users after updating status
    } catch (error) {
      setSnackbarMessage("Erreur lors de la mise à jour du statut de l'utilisateur");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      console.log('userId', userId);
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.status === 200) {
        setSnackbarMessage("Utilisateur supprimé avec succès");
        setSnackbarOpen(true);
        handleCloseDialog();
        refetch(); // Refetch users after deleting user
      } else {
        setSnackbarMessage("Erreur lors de la suppression de l'utilisateur");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Erreur lors de la suppression de l'utilisateur");
      setSnackbarOpen(true);
    }
  };

  // Custom formatter for actions column
  const actionsFormatter = (cell: any, formatterParams: any, onRendered: any) => {
    const user = cell.getRow().getData();
    const div = document.createElement('div');

    const viewButton = document.createElement('button');
    viewButton.innerText = 'Voir';
    viewButton.className = 'edit-btn';
    viewButton.onclick = () => handleOpenDialog(user);

    div.appendChild(viewButton);

    return div;
  };

  // Configuration des colonnes pour Tabulator
  const columns = [
    {
      title: "Date d'inscription", field: "createdAt", formatter: (cell: any) => {
        return new Date(cell.getValue()).toLocaleDateString();
      }, formatterParams: { outputFormat: "DD/MM/YYYY" }
    },
    { title: "Téléphone", field: "phone" },
    { title: "Nom", field: "lastName", resizable: false, responsive: 0 , maxWidth: 200},
    { title: "Prénom", field: "firstName", maxWidth: 200 },
    { title: "Email", field: "email", maxWidth: 200 },
    {
      title: "Role",
      field: "roles",
      formatter: (cell: any) => cell.getValue().toUpperCase()  // Affiche le rôle en majuscule
    },
    { 
      title: "Confirmation", 
      field: "confirmed", 
      formatter: (cell: any) => cell.getValue() ? '<span style="color: green;">Confirmé</span>' : '<span style="color: red;">Non Confirmé</span>',
    },
    { 
      title: "Status", 
      field: "status", 
      formatter: (cell: any) => cell.getRow().getData().confirmed ? (cell.getValue() ? '<span style="color: green;">Actif</span>' : '<span style="color: red;">Bloqué</span>') : '<span style="color: red;">Inactif</span>',
    },
    {
      title: "Action",
      field: "action",
      formatter: actionsFormatter,
      headerSort: false
    }
  ];

  const DetailField = ({ label, value }: { label: string; value: any }) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">
        {value || '-'}
      </Typography>
    </Box>
  );

  return (
    <>
      <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
        <Typography
          variant="h5"
          sx={{ mb: 2, color: "#3f51b5", textAlign: { xs: "center", md: "left" } }}
        >
          Liste des utilisateurs
        </Typography>

        <ReactTabulator
          data={filteredUsers}
          columns={columns}
          options={{
            layout: "fitDataTable",
            responsiveLayout: "collapse",
            pagination: "local",
            locale:"fr-fr",
            langs:{
              "fr-fr": {
                "pagination": {
                "page_size": "Taille de page",
                "first": "Premier",
                "first_title": "Première Page",
                "last": "Dernier",
                "last_title": "Dernière Page",
                "prev": "Précédent",
                "prev_title": "Page Précédente",
                "next": "Suivant",
                "next_title": "Page Suivante",
                "all": "Tous"
                }
              }
            },
            paginationSize: 10,
            paginationSizeSelector: [5, 10, 25],
            movableColumns: true,
            placeholder: "Aucun utilisateur trouvé",
            maxHeight: "50vh",
            initialSort: [
              { column: "createdAt", dir: "desc" }
            ],
            rowFormatter: function (row: any) {
              // Add custom CSS to rows
              const element = row.getElement();
              element.style.borderBottom = "1px solid #ddd";
            },
            virtualDom: true,
            renderVertical: 'basic', // Optimiser le rendu vertical
            renderHorizontal: 'virtual', // Optimiser le rendu horizontal
            resizableColumns: false, // Désactiver le redimensionnement des colonnes pour éviter les problèmes d'observateur de redimensionnement
            responsiveLayoutCollapseStartOpen: false,
            columnDefaults: {
              resizable: false, // Désactiver le redimensionnement pour toutes les colonnes par défaut
            },
          }}
        />
      </Box>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Informations utilisateur</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <DetailField 
                    label="Nom complet" 
                    value={`${selectedUser.firstName} ${selectedUser.lastName}`} 
                  />
                  <DetailField 
                    label="Email" 
                    value={selectedUser.email} 
                  />
                  <DetailField 
                    label="Téléphone" 
                    value={selectedUser.phone} 
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Avatar sx={{ width: 200, height: 200 }}>
                      {selectedUser.avatar ? (
                        <img src={`${process.env.REACT_APP_API_URL}/files/avatars/${selectedUser.avatar}`} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      ) : (
                        <PersonIcon />
                      )}
                    </Avatar>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Informations système
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DetailField 
                        label="Date de création" 
                        value={new Date(selectedUser.createdAt).toLocaleString()} 
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DetailField 
                        label="Dernière mise à jour" 
                        value={new Date(selectedUser.updatedAt).toLocaleString()} 
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="primary">
            Fermer
          </Button>
          {selectedUser && selectedUser.confirmed && userRole !== 'agent' && (
            <Button onClick={() => selectedUser && handleDeactivateUser(selectedUser.id, !selectedUser.status)} variant="contained" color={selectedUser.status ? "error" : "success"}>
              {selectedUser.status ? "Désactiver" : "Réactiver"}
            </Button>
          )}
          {selectedUser && userRole !== 'agent' && (
            <Button onClick={() => selectedUser && handleDeleteUser(selectedUser.id)} variant="contained" color="error">
              Supprimer
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserListing;