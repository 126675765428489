import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Paper,
  Grid,
  Chip,
  FormHelperText,
  IconButton,
  Alert,
} from "@mui/material";
import useQueryProperty from "../queries/useQueryProperty";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { X, Upload, ImagePlus } from "lucide-react";
import { useCreatePropertyMutation, useUploadPhotosPropertyMutation } from "../mutations/usePropertyMutation";

interface FormErrors {
  propertyType: string;
  title: string;
  city: string;
  commune: string;
  district: string;
  street: string;
  rooms: string;
  bathRoom: string;
  bedRoom: string;
  description: string;
  price: string;
  area: string;
  user: string;
  mainImage: string;
}

interface PhotoInput {
  id: string;
  file: File | null;
  preview: string;
  error: string;
}

const PropertyForm = () => {
  const [propertyType, setPropertyType] = useState("");
  const [city, setCity] = useState("");
  const [commune, setCommune] = useState(0);
  const [district, setDistrict] = useState(0);
  const [title, setTitle] = useState("");
  const [street, setStreet] = useState("");
  const [rooms, setRooms] = useState(1);
  const [bedRoom, setbedRoom] = useState(0);
  const [number, setNumber] = useState("");
  const [bathRoom, setBathRoom] = useState(0);
  const [photoInputs, setPhotoInputs] = useState<PhotoInput[]>([]);
  const maxFileSize = 1024 * 1024; // 1MB in bytes
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(1);
  const [transactionType, setTransactionType] = useState("vente");
  const [user, setUser] = useState<string>("");
  const [characteristic, setCharacteristic] = useState<string[]>([]);
  const [area, setArea] = useState(1);
  const [parking, setParking] = useState(0);
  const [mainImage, setMainImage] = useState<File | null>(null);
  const [galleryImages, setGalleryImages] = useState<File[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const validatePhotoInput = (file: File): string => {
    if (file.size > maxFileSize) {
      return "L'image doit être inférieure à 1Mo";
    }
    if (!file.type.startsWith('image/')) {
      return "Seuls les fichiers image sont acceptés";
    }
    return "";
  };

  const handlePhotoAdd = () => {
    setPhotoInputs(prev => [
      ...prev,
      {
        id: crypto.randomUUID(),
        file: null,
        preview: "",
        error: ""
      }
    ]);
  };

  const handlePhotoRemove = (id: string) => {
    setPhotoInputs(prev => prev.filter(input => input.id !== id));
  };

  const handlePhotoChange = (id: string, file: File | null) => {
    setPhotoInputs(prev => prev.map(input => {
      if (input.id === id) {
        const error = file ? validatePhotoInput(file) : "";
        const preview = file && !error ? URL.createObjectURL(file) : "";
        return {
          ...input,
          file,
          preview,
          error
        };
      }
      return input;
    }));
  };

  const [errors, setErrors] = useState<FormErrors>({
    propertyType: "",
    title: "",
    city: "",
    commune: "",
    district: "",
    street: "",
    rooms: "",
    bathRoom: "",
    bedRoom: "",
    description: "",
    price: "",
    area: "",
    user: "",
    mainImage: "",
  });

  const { useCommunes, useAgents } = useQueryProperty();
  const { data: communes } = useCommunes();
  const { data: agents } = useAgents();

  const createPropertyMutation = useCreatePropertyMutation();
  const uploadImages = useUploadPhotosPropertyMutation();

  const aminities = [
    "Balcon",
    "Jardin",
    "Piscine",
    "Garage",
    "Cheminée",
    "Terrasse",
    "Climatisation",
    "Panneaux Solaires",
    "Système de Sécurité",
    "Ascenseur",
    "Salle de Sport",
    "Aire de Jeux",
    "Sauna",
    "Jacuzzi",
    "Sous-Sol",
    "Bureau à Domicile",
    "Dressing",
    "Buanderie",
    "Cuisine Extérieure",
    "Toit-Terrasse",
    "Cave à Vin",
    "Fonctionnalités Domotiques"
  ];

  const validateForm = () => {
    const newErrors: FormErrors = {
      propertyType: !propertyType ? "Le type de bien est requis" : "",
      title: !title.trim() ? "Le titre est requis" : "",
      city: !city ? "La ville est requise" : "",
      commune: !commune ? "La commune est requise" : "",
      district: !district ? "Le quartier est requis" : "",
      street: !street.trim() ? "La rue est requise" : "",
      rooms: rooms < 1 ? "Le nombre de pièces doit être supérieur à 0" : "",
      bedRoom: bedRoom < 0 ? "Le nombre de chambres doit être supérieur à 0" : "",
      bathRoom: bathRoom < 0 ? "Le nombre de salles de bain doit être supérieur à 0" : "",
      description: !description.trim() ? "La description est requise" : "",
      price: price < 1 ? "Le prix doit être supérieur à 0" : "",
      area: area < 1 ? "La surface doit être supérieure à 0" : "",
      user: !user ? "L'agent est requis" : "",
      mainImage: !mainImage ? "L'image principale est requise" : "",
    };

    setErrors(newErrors);
    const valid = !Object.values(newErrors).some(error => error !== "");
    setIsFormValid(valid);
    return valid;
  };

  useEffect(() => {
    validateForm();
  }, [propertyType, title, city, commune, district, street, rooms, bedRoom, bathRoom, description, price, area, user, mainImage]);

  useEffect(() => {
    if (commune !== null) {
      setDistrict(0);
    }
  }, [commune, communes]);

  useEffect(() => {
    // Cleanup previews on unmount
    return () => {
      photoInputs.forEach(input => {
        if (input.preview) {
          URL.revokeObjectURL(input.preview);
        }
      });
    };
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof characteristic>) => {
    const {
      target: { value },
    } = event;
    setCharacteristic(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleAgentChange = (event: React.SyntheticEvent, value: any) => {
    setUser(value?.id || "");
  };

  const handleMainImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setMainImage(file);
    }
  };

  const handleGalleryImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    setGalleryImages(files);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const newProperty = {
      type: propertyType,
      title,
      category: transactionType,
      city,
      rooms,
      bathRoom,
      bedRoom,
      description,
      price,
      characteristic,
      area,
      street,
      parking,
      number,
      commune: { connect: { id: commune } },
      district: { connect: { id: district } },
      agentId: user,
    };

    try {
      const createdProperty = await createPropertyMutation.mutateAsync(newProperty);
      console.log("Property created:", createdProperty);

      if (createdProperty && (mainImage || photoInputs.length > 0)) {
        const formData = new FormData();

        if (mainImage) {
          formData.append("photos", mainImage);
        }

        photoInputs.forEach(input => {
          if (input.file && !input.error) {
            formData.append("photos", input.file);
          }
        });

        await uploadImages.mutateAsync({
          propertyId: createdProperty.id,
          formData,
        });

        console.log("Images uploaded successfully");
      }
    } catch (error) {
      console.error("Error submitting property or uploading images:", error);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1600, mx: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Les biens immobiliers
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            row
            value={transactionType}
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <FormControlLabel
              value="Location"
              control={<Radio />}
              label="Location"
            />
            <FormControlLabel value="Achat" control={<Radio />} label="Achat" />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth margin="normal" error={!!errors.propertyType}>
          <InputLabel>Type de bien *</InputLabel>
          <Select
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
            label="Type de bien *"
          >
            <MenuItem value="Villa">Villa</MenuItem>
            <MenuItem value="Appartement">Appartement</MenuItem>
            <MenuItem value="Immeuble">Immeuble</MenuItem>
            <MenuItem value="Entrepot">Entrepôt</MenuItem>
            <MenuItem value="Chambre">Chambre</MenuItem>
            <MenuItem value="Studio">Studio</MenuItem>
            <MenuItem value="BatimentCommercial">Batiment commercial</MenuItem>
            <MenuItem value="Parcelle">Parcelle</MenuItem>
          </Select>
          {errors.propertyType && <FormHelperText>{errors.propertyType}</FormHelperText>}
        </FormControl>

        <TextField
          fullWidth
          required
          label="Nom du bien immobilier"
          margin="normal"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={!!errors.title}
          helperText={errors.title}
        />

        <FormControl fullWidth margin="normal" error={!!errors.city}>
          <InputLabel>Ville *</InputLabel>
          <Select
            value={city}
            onChange={(e) => setCity(e.target.value)}
            label="Ville *"
          >
            <MenuItem value="kinshasa">Kinshasa</MenuItem>
          </Select>
          {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth margin="normal" error={!!errors.commune}>
          <InputLabel>Commune *</InputLabel>
          <Select
            value={commune || ""}
            onChange={(e: any) => setCommune(parseInt(e.target.value))}
            label="Commune *"
          >
            <MenuItem value="">-- Sélectionner une commune --</MenuItem>
            {communes?.map((commune: any) => (
              <MenuItem key={commune.id} value={commune.id}>
                {commune.name}
              </MenuItem>
            ))}
          </Select>
          {errors.commune && <FormHelperText>{errors.commune}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth margin="normal" error={!!errors.district}>
          <InputLabel>Quartier *</InputLabel>
          <Select
            value={district}
            onChange={(e: any) => setDistrict(parseInt(e.target.value))}
            label="Quartier *"
          >
            <MenuItem value="">-- Sélectionner un quartier --</MenuItem>
            {communes
              ?.find((c: any) => c.id === commune)
              ?.quartiers
              .map((district: any) => (
                <MenuItem key={district.id} value={district.id}>
                  {district.name}
                </MenuItem>
              ))}
          </Select>
          {errors.district && <FormHelperText>{errors.district}</FormHelperText>}
        </FormControl>

        <TextField
          fullWidth
          required
          label="Rue"
          type="text"
          margin="normal"
          variant="outlined"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          error={!!errors.street}
          helperText={errors.street}
        />

        <TextField
          fullWidth
          label="Numéro"
          type="text"
          margin="normal"
          variant="outlined"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />

        <TextField
          fullWidth
          required
          label="Nombre de pièces"
          type="number"
          margin="normal"
          variant="outlined"
          value={rooms}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setRooms(value < 1 ? 1 : value);
          }}
          error={!!errors.rooms}
          helperText={errors.rooms}
          inputMode="numeric"
        />

         <TextField
          fullWidth
          required
          label="Nombre de Chambres"
          type="number"
          margin="normal"
          variant="outlined"
          value={bedRoom}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setbedRoom(value < 0 ? 0 : value);
          }}
          error={!!errors.bedRoom}
          helperText={errors.bedRoom}
        />

        <TextField
          fullWidth
          required
          label="Nombre de salles de bain"
          type="number"
          margin="normal"
          variant="outlined"
          value={bathRoom}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setBathRoom(value < 0 ? 0 : value);
          }}
          error={!!errors.bathRoom}
          helperText={errors.bathRoom}
        />

        <TextField
          fullWidth
          required
          label="Surface"
          type="number"
          margin="normal"
          variant="outlined"
          value={area}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setArea(value < 1 ? 1 : value);
          }}
          error={!!errors.area}
          helperText={errors.area}
        />

        <TextField
          fullWidth
          label="Parking"
          type="number"
          margin="normal"
          variant="outlined"
          value={parking}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setParking(value < 0 ? 0 : value);
          }}
        />

        <TextField
          fullWidth
          required
          label="Description"
          multiline
          rows={4}
          margin="normal"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={!!errors.description}
          helperText={errors.description}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="demo-multiple-checkbox-label">Caractéristiques</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={characteristic}
            onChange={handleChange}
            input={<OutlinedInput label="Caractéristiques" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {aminities.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={characteristic.includes(name)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          label="Prix"
          type="number"
          margin="normal"
          variant="outlined"
          value={price}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setPrice(value < 0 ? 0 : value);
          }}
          error={!!errors.price}
          helperText={errors.price}
        />

        <FormControl fullWidth margin="normal" error={!!errors.user}>
          <Autocomplete
            options={agents || []}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
            onChange={handleAgentChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Agent *"
                variant="outlined"
                error={!!errors.user}
                helperText={errors.user}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.firstName} {option.lastName} - {option.email}
              </Box>
            )}
          />
        </FormControl>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Image principale *
          </Typography>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="main-image-upload"
            type="file"
            onChange={handleMainImageChange}
          />
          <label htmlFor="main-image-upload">
            <Button variant="contained" component="span">
              Télécharger l'image principale
            </Button>
          </label>
          {errors.mainImage && (
            <FormHelperText error>{errors.mainImage}</FormHelperText>
          )}
          {mainImage && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Aperçu de l'image principale :</Typography>
              <img
                src={URL.createObjectURL(mainImage)}
                alt="Aperçu de l'image principale"
                style={{ width: "200px", height: "auto", borderRadius: "8px" }}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Galerie d'images
          </Typography>

          <Grid container spacing={2}>
            {photoInputs.map((input) => (
              <Grid item xs={12} sm={6} md={4} key={input.id}>
                <Box
                  sx={{
                    border: '2px dashed',
                    borderColor: input.error ? 'error.main' : 'primary.main',
                    borderRadius: 2,
                    p: 2,
                    position: 'relative',
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'background.paper',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      borderColor: 'primary.dark',
                      backgroundColor: 'action.hover',
                    }
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handlePhotoChange(input.id, e.target.files?.[0] || null)}
                    style={{ display: 'none' }}
                    id={`photo-input-${input.id}`}
                  />

                  {input.preview ? (
                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                      <img
                        src={input.preview}
                        alt="Aperçu"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: -12,
                          right: -12,
                          backgroundColor: 'background.paper',
                          '&:hover': { backgroundColor: 'action.hover' },
                        }}
                        onClick={() => handlePhotoChange(input.id, null)}
                      >
                        <X className="w-4 h-4" />
                      </IconButton>
                    </Box>
                  ) : (
                    <label htmlFor={`photo-input-${input.id}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Upload className="w-8 h-8 mb-2" />
                        <Typography variant="body2" color="textSecondary" align="center">
                          Cliquez ou glissez une image ici
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Max: 1Mo
                        </Typography>
                      </Box>
                    </label>
                  )}

                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: -12,
                      right: -12,
                      backgroundColor: 'error.main',
                      color: 'white',
                      '&:hover': { backgroundColor: 'error.dark' },
                    }}
                    onClick={() => handlePhotoRemove(input.id)}
                  >
                    <X className="w-4 h-4" />
                  </IconButton>

                  {input.error && (
                    <Alert
                      severity="error"
                      sx={{
                        position: 'absolute',
                        bottom: -8,
                        left: 0,
                        right: 0,
                        fontSize: '0.75rem',
                        py: 0
                      }}
                    >
                      {input.error}
                    </Alert>
                  )}
                </Box>
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: '200px',
                  borderStyle: 'dashed',
                  borderWidth: '2px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}
                onClick={handlePhotoAdd}
              >
                <ImagePlus className="w-8 h-8" />
                <Typography>Ajouter une photo</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isFormValid}
          >
            Soumettre
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PropertyForm;