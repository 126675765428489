import { useQuery } from "@tanstack/react-query";
import { SearchParams } from "../services/property";
import { getUser, getUsers } from "../services/user";

const useQueryUser = () => {
  const useOne = (id: string) => {
    return useQuery({
      queryKey: ["user", id],
      queryFn: () => getUser(id),
      refetchOnWindowFocus: true,
    });
  };

  const useAll = (variable: SearchParams) => {
    return useQuery({
      queryKey: ["users", variable],
      queryFn: () => getUsers(variable),
      refetchOnWindowFocus: true,
    });
  };

  return {
    getOneUser: useOne,
    getAllUsers: useAll,
  };
};

export default useQueryUser;
