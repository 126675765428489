import api from "./api";

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginResponse {
  data: {
    accessToken: string;
    user: {
      id: number;
      email: string;
      roles: string;
    };
  };
}
export const loginUser = async (
  credentials: LoginCredentials
): Promise<LoginResponse> => {
  const { data } = await api.post<LoginResponse>(
    "/auth/sign-in-admin",
    credentials
  );
  return data;
};

export const logoutUser = async (): Promise<void> => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  await api.post("/auth/logout");
};
