import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import toast from 'react-hot-toast';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { Layout } from "./components/layout/Layout";
import Message from "./pages/Message";
import PropertyListing from "./pages/Properties";
import AddProperty from "./pages/AddProperty";
import UserListing from "./pages/Utilisateur";
import AddUsers from "./pages/AddUser";
import PropertyDetails from "./pages/PropertyDetails";
import SellerContacts from "./pages/Contacts";
import UpdateProperty from "./pages/UpdateProperty";
import PropertyAlerts from "./pages/Alerts";
import AgentListing from "./pages/NonCustomerUserListing"
import MonCompte from "./pages/MonCompte";
import UserProfile from "./pages/UserProfilAgent";
import RatingList from "./pages/RatingList";

const queryClient = new QueryClient();
const isAuthenticated = () => {
  return localStorage.getItem("token") !== null;
};

const getUserRole = () => {
  return localStorage.getItem("role"); // superadmin, admin, agent
};


interface PrivateRouteProps {
  element: React.ReactElement;
  allowedRoles: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, allowedRoles }) => {
  const location = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const userRole = getUserRole();
  if (!allowedRoles.includes(userRole || "")) {
    toast.error("Vous n'êtes pas autorisé à accéder à cette page", {
      id: "authorization-error", // Pour éviter l'affichage multiple du même toast
    });
    // Redirection vers une page d'erreur ou une page spécifique si l'utilisateur n'a pas les droits
    return <Navigate to="/dashboard" replace />;
  }

  return element;
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route
              path="/dashboard"
              element={<PrivateRoute element={<Dashboard />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/messages"
              element={<PrivateRoute element={<Message />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/property"
              element={<PrivateRoute element={<PropertyListing />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/property/add"
              element={<PrivateRoute element={<AddProperty />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/agents/rating/:id"
              element={<PrivateRoute element={<RatingList />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/property/update/:id"
              element={<PrivateRoute element={<UpdateProperty />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/property/details/:id"
              element={<PrivateRoute element={<PropertyDetails />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/users"
              element={<PrivateRoute element={<UserListing />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />
            <Route
              path="/agents"
              element={<PrivateRoute element={<AgentListing />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/agents/:id"
              element={<PrivateRoute element={<UserProfile />} allowedRoles={['superadmin', 'admin']} />}
            />


            <Route
              path="/agents/add"
              element={<PrivateRoute element={<AddUsers />} allowedRoles={['superadmin', 'admin']} />}
            />

            <Route
              path="/mon-compte"
              element={<PrivateRoute element={<MonCompte />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/seller-contacts"
              element={<PrivateRoute element={<SellerContacts />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/alerts"
              element={<PrivateRoute element={<PropertyAlerts />} allowedRoles={['superadmin', 'admin', 'agent']} />}
            />

            <Route
              path="/"
              element={
                isAuthenticated() ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>

        </Layout>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
