import React, { useEffect, useState } from 'react';
import { ReactTabulator } from 'react-tabulator';
import axios from 'axios';
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Paper,
} from '@mui/material';
import { Eye } from 'lucide-react';

interface Criteria {
  area?: { min: number; max: number };
  type?: string[];
  price?: { min: number; max: number };
  rooms?: number;
  bathRoom?: number;
  commune?: string;
  quartier?: string[];
}

interface AlertData {
  id: string;
  userId: string;
  criteria: Criteria;
  createdAt: string;
  updatedAt: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
}

const PropertyAlerts = () => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'success'>('error');
  const [selectedAlert, setSelectedAlert] = useState<AlertData | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/alerts`);
        setAlerts(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch alerts.');
        setLoading(false);
        showSnackbar('Erreur lors du chargement des alertes', 'error');
      }
    };

    fetchAlerts();
  }, []);

  const handleViewClick = (e: any, cell: any) => {
    e.stopPropagation(); // Empêche la propagation au rowClick
    const row = cell.getRow();
    setSelectedAlert(row.getData());
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAlert(null);
  };

  const showSnackbar = (message: string, severity: 'error' | 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const DetailField = ({ label, value }: { label: string; value: any }) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">
        {value || '-'}
      </Typography>
    </Box>
  );

  const columns = [
    { 
      title: "Utilisateur", 
      field: "user", 
      headerSort: false,
      formatter: (cell: any) => {
        const user = cell.getValue();
        return `${user.firstName} ${user.lastName}`;
      }, 
      maxWidth: 200
    },
    { 
      title: "Email", 
      field: "user.email",
      headerSort: false,
      maxWidth: 200
      
    },
    { 
      title: "Téléphone", 
      field: "user.phone",
      headerSort: false,
    },
    {
      title: "Type de bien",
      field: "criteria.type",
      headerSort: false,
      formatter: (cell: any) => {
        const types = cell.getValue();
        return types ? types.join(', ') : '';
      },
      maxWidth: 200
    },
    {
      title: "Prix",
      field: "criteria.price",
      headerSort: false,
      formatter: (cell: any) => {
        const price = cell.getValue();
        return price ? `${price.min}€ - ${price.max}€` : '';
      }
    },
    {
      title: "Surface",
      field: "criteria.area",
      headerSort: false,
      formatter: (cell: any) => {
        const area = cell.getValue();
        return area ? `${area.min} - ${area.max} m²` : '';
      }
    },
    {
      title: "Chambres",
      field: "criteria.rooms",
      headerSort: false,
    },
    {
      title: "SDB",
      field: "criteria.bathRoom",
      headerSort: false,
    },
    {
      title: "Commune",
      field: "criteria.commune",
      headerSort: false,
    },
    {
      title: "Date de création",
      field: "createdAt",
      headerSort: false,
      formatter: (cell: any) => {
        return new Date(cell.getValue()).toLocaleDateString();
      }
    },
    {
      title: "Action",
      headerSort: false,
      formatter: () => {
        return '<button class="view-btn" style="background-color: #1976d2; color: white; border: none; padding: 6px 16px; border-radius: 4px; cursor: pointer; display: flex; align-items: center; gap: 4px;"><span>Voir</span></button>';
      },
      cellClick: handleViewClick,
      width: 100,
      // hozAlign: "center",
    }
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, color: "#3f51b5", textAlign: { xs: "center", md: "left" } }}
      >
        Liste des alertes immobilières
      </Typography>

      <ReactTabulator
        data={alerts}
        columns={columns}
        options={{
          layout: "fitDataTable",
          responsiveLayout: "collapse",
          pagination: "local",
          locale:"fr-fr",
          langs:{
            "fr-fr": {
              "pagination": {
              "page_size": "Taille de page",
              "first": "Premier",
              "first_title": "Première Page",
              "last": "Dernier",
              "last_title": "Dernière Page",
              "prev": "Précédent",
              "prev_title": "Page Précédente",
              "next": "Suivant",
              "next_title": "Page Suivante",
              "all": "Tous"
              }
            }
          },
          paginationSize: 10,
          paginationSizeSelector: [5, 10, 25],
          movableColumns: false,
          placeholder: "Aucune alerte trouvée",
          maxHeight: "70vh",
          virtualDom: true,
          renderVertical: 'basic',
          renderHorizontal: 'virtual',
          resizableColumns: false,
          responsiveLayoutCollapseStartOpen: false,
          columnDefaults: {
            resizable: false,
          },
        }}
      />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Détails de l'alerte
        </DialogTitle>
        <DialogContent>
          {selectedAlert && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Informations utilisateur
                  </Typography>
                  <DetailField 
                    label="Nom complet" 
                    value={`${selectedAlert.user.firstName} ${selectedAlert.user.lastName}`} 
                  />
                  <DetailField 
                    label="Email" 
                    value={selectedAlert.user.email} 
                  />
                  <DetailField 
                    label="Téléphone" 
                    value={selectedAlert.user.phone} 
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Critères de recherche
                  </Typography>
                  <DetailField 
                    label="Type de bien" 
                    value={selectedAlert.criteria.type?.join(', ')} 
                  />
                  <DetailField 
                    label="Prix" 
                    value={selectedAlert.criteria.price ? 
                      `${selectedAlert.criteria.price.min}€ - ${selectedAlert.criteria.price.max}€` : 
                      null
                    } 
                  />
                  <DetailField 
                    label="Surface" 
                    value={selectedAlert.criteria.area ? 
                      `${selectedAlert.criteria.area.min} - ${selectedAlert.criteria.area.max} m²` : 
                      null
                    } 
                  />
                  <DetailField 
                    label="Nombre de chambres" 
                    value={selectedAlert.criteria.rooms} 
                  />
                  <DetailField 
                    label="Nombre de salles de bain" 
                    value={selectedAlert.criteria.bathRoom} 
                  />
                  <DetailField 
                    label="Commune" 
                    value={selectedAlert.criteria.commune} 
                  />
                  <DetailField 
                    label="Quartiers" 
                    value={selectedAlert.criteria.quartier?.join(', ')} 
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Informations système
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DetailField 
                        label="Date de création" 
                        value={new Date(selectedAlert.createdAt).toLocaleString()} 
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DetailField 
                        label="Dernière mise à jour" 
                        value={new Date(selectedAlert.updatedAt).toLocaleString()} 
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity} 
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PropertyAlerts;