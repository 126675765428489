export const colors = {
  darkPurple: "#231d54",
  purple: "#8100ff",
  lightPurple: "#9388db",
  lightGrayPurple: "#f7f7fb",
  pink: "#ff3d69",
  gray: "#797777",
  black: "#000000",
  white: "#ffffff",
  transparent: "transparent",
  red: "#ef233c",
  redDark: "#b71d27",
  blackLight: "#343a40",
  grey: "#8d99ae",
  greyLight: "#F5F5F5",
  greyDark: "#6c757d",
  blue: "#1855CD",
  blueLight: "#00b4d8",
  blueDark: "#023e8a",
  green: "#008000",
  greenSalad: "#4CAF50",
  lightGreen: "#90EE90",
};
