import React from "react";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  ListItemButton,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PeopleIcon from "@mui/icons-material/People";
import MessageIcon from "@mui/icons-material/Message";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import ContactsIcon from "@mui/icons-material/Contacts";
import MonCompteIcon from "@mui/icons-material/Home"; // Add this line
import logo from "./../../assets/logo.png";
import { colors } from "../../utils/constant";
import { useNavigate, useLocation } from "react-router-dom";

import { useLogoutMutation } from "../../mutations/useLoginMutation";

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DrawerContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();
  const menuItems = [
    { text: "Tableau de bord", icon: <DashboardIcon />, path: "/dashboard" },
    { text: "Immobilier", icon: <HomeWorkIcon />, path: "/property" },
    { text: "Utilisateurs", icon: <PeopleIcon />, path: "/users" },
    { text: "Agents", icon: <PeopleIcon />, path: "/agents" },
    { text: "Messages", icon: <MessageIcon />, path: "/messages" },
    { text: "Alertes", icon: <NotificationsIcon />, path: "/alerts" },
    { text: "Contacts et demandes", icon: <ContactsIcon />, path: "/seller-contacts" },
    { text: "Mon Compte", icon: <MonCompteIcon />, path: "/mon-compte" },
  ];

  const handleClick = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const isActive = (path: string) => {
    return location.pathname === path || location.pathname.startsWith(path);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          p: 2,
          height: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxHeight: "100%", width: "auto", borderRadius: "10px" }}
        />
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => handleClick(item.path)}
            sx={{
              backgroundColor: isActive(item.path) ? colors.blue : "inherit",
              color: isActive(item.path) ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive(item.path)
                  ? "darkblue"
                  : "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: isActive(item.path) ? "white" : "inherit",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<ExitToAppIcon />}
          fullWidth
          onClick={handleLogout}
        >
          Déconnexion
        </Button>
      </Box>
    </Box>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" }, position: "fixed", top: 10, left: 10 }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {isSmUp ? (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
            }}
            open
          >
            <DrawerContent />
          </Drawer>
        ) : (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
            }}
          >
            <DrawerContent />
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 240px)` },
          mt: { xs: 7, sm: 8 },
        }}
      >
        {/* Main content goes here */}
      </Box>
    </Box>
  );
};

export default Sidebar;