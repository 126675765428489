import { useQuery } from "@tanstack/react-query";
import { getProperties, getProperty, SearchParams, getCommunes, getAgents } from "../services/property";
import { Property } from "../types/property.types";

const useQueryProperty = () => {
  const useOne = (id?: string) => {
    return useQuery({
      queryKey: ["property", id],
      queryFn: () => getProperty(id),
      refetchOnWindowFocus: true,
    });
  };

  const useAll = (variable: SearchParams) => {
    return useQuery({
      queryKey: ["properties", variable],
      queryFn: () => getProperties(variable),
      refetchOnWindowFocus: true,
    });
  };

  const useCommunes = () => {
    return useQuery({
      queryKey: ['contact/communes'],
      queryFn: getCommunes,
      refetchOnWindowFocus: true,
    })
  }

    // Récupérer la liste des agents
    const useAgents = () => {
      return useQuery({
        queryKey: ["users"],
        queryFn: getAgents,
        refetchOnWindowFocus: true,
      });
    };

  return {
    getOneProperty: useOne,
    getAllProperties: useAll,
    useCommunes: useCommunes,
    useAgents: useAgents,
  };
};

export default useQueryProperty;
