import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useLoginMutation } from "../mutations/useLoginMutation";
import logo from "../assets/logo.png";
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#ef233c",
    },
  },
});

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: theme.palette.grey[100],
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 400,
  width: "100%",
}));

const StyledForm = styled("form")({
  width: "100%",
  marginTop: 1,
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginMutation = useLoginMutation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    loginMutation.mutate({ email, password });
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer>
        <StyledPaper elevation={3}>
          <img
            width={122}
            style={{ marginBottom: 20, borderRadius: 100 }}
            src={logo}
            alt="Logo"
            className="logo"
          />
          <Typography component="h1" variant="h4" gutterBottom>
            Connexion
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            {loginMutation.isError && (
              <Typography color="error" align="center" gutterBottom>
                { "Identifiant ou mot de passe incorrect"}
              </Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box mt={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                disabled={loginMutation.isPending}
              >
                {loginMutation.isPending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Se connecter"
                )}
              </Button>
            </Box>
          </StyledForm>
        </StyledPaper>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default Login;
