import React, { useState } from "react";
import {
    Typography,
    TextField,
    Button,
    Paper,
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Snackbar,
    Alert,
    Avatar,
    CircularProgress,
} from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useCreateUserMutation } from "../mutations/useUserMutation";

const AddUsers = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState(""); // Initial phone code for RDC
    const [email, setEmail] = useState("");
    const [roles, setRoles] = useState("agent");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const createUserMutation = useCreateUserMutation();
    const userRole = localStorage.getItem("role");

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const newUser = {
            firstName,
            lastName,
            phone,
            email,
            roles,
        };

        try {
            const createdUser = await createUserMutation.mutateAsync(newUser);

            if (imageFile) {
                const formData = new FormData();
                formData.append('file', imageFile);

                await fetch(`${process.env.REACT_APP_API_URL}/users/${createdUser.id}/avatar`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: formData,
                });
            }

            setSnackbarMessage("User created successfully");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        } catch (error) {
            setSnackbarMessage("L'email existe dejà or une erreur s'est produite");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: "auto" }}>
            <Typography variant="h5" sx={{ mb: 2, color: "#3f51b5" }}>
                {userRole === "superadmin" ? "Créer un compte Admin ou Agent" : "Créer un compte Agent"}
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <input
                        accept="image/*"
                        type="file"
                        style={{ display: 'none' }}
                        id="profile-picture-upload"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="profile-picture-upload">
                        <Avatar
                            src={imagePreview || undefined}
                            sx={{ width: 100, height: 100, cursor: 'pointer' }}
                        />
                    </label>
                </Box>
                <TextField
                    fullWidth
                    label="Prénom"
                    margin="normal"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                <TextField
                    fullWidth
                    label="Nom"
                    margin="normal"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                <PhoneInput
                    country={'cd'} // RDC (Congo) country code
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputStyle={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
                    containerStyle={{ width: '100%' }}
                />
                <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <FormControl component="fieldset" margin="normal" required>
                    <FormLabel component="legend">Rôle</FormLabel>
                    <RadioGroup
                        value={roles}
                        onChange={(e) => setRoles(e.target.value)}
                        row
                    >
                        {userRole === "superadmin" && (
                            <>
                                <FormControlLabel
                                    value="superadmin"
                                    control={<Radio />}
                                    label="Superadmin"
                                />
                                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                            </>
                        )}
                        {(userRole === "superadmin" || userRole === "admin") && (
                            <FormControlLabel value="agent" control={<Radio />} label="Agent" />
                        )}
                    </RadioGroup>
                </FormControl>

                <Box sx={{ mt: 3 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Envoyer l'invitation"}
                    </Button>
                </Box>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AddUsers;