import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isLoginPage = location.pathname === "/login";

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", direction: "row" }}>
      {!isLoginPage && (
        <Sidebar isOpen={mobileOpen} onClose={handleDrawerToggle} />
      )}

      <Container
        maxWidth={isLoginPage ? "sm" : "lg"}
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItem: "center",
          flexDirection: "column",
          // py: { xs: 2, sm: 3 },
          // px: { xs: 2, sm: 3 },
          margin: 0,
          width: "100%", 
          maxWidth: {
            xs: "100%", // Pour les petits écrans
            md: "100%", // Moyens écrans
            lg: "100%", // Grand écrans (min-width 1200px)
          },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export { Sidebar,Layout };
