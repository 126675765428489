import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { loginUser, logoutUser } from "../services/auth";

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginResponse {
  data: {
    accessToken: string;
    user: {
      id: number;
      email: string;
      roles: string;
    };
  };
}

export const useLoginMutation = () => {
  const navigate = useNavigate();

  return useMutation<LoginResponse, Error, LoginCredentials>({
    mutationFn: loginUser,
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      localStorage.setItem("role", data.data.user.roles);
      localStorage.setItem("id", data.data.user.id.toString());
      navigate("/dashboard");
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
  });
};

export const useLogoutMutation = () => {
  const navigate = useNavigate();

  return useMutation<void, Error, void>({
    mutationFn: logoutUser,
    onSuccess: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("id");
      navigate("/login");
    },
    onError: (error) => {
      console.error("Logout failed:", error);
    },
  });
};
