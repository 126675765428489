import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Button,
  styled,
  useTheme,
} from '@mui/material';
import {
  Message as MessageIcon,
  Send as SendIcon,
  Build as BuildIcon,
  More as MoreIcon,
  ArrowBack as ArrowBackIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // Import Tabulator CSS
import 'react-tabulator/css/tabulator.min.css'; // Import Tabulator CSS
import { DateTime } from 'luxon';

interface Message {
  id: string;
  content: string;
  senderId: string;
  conversationId: string;
  createdAt: string;
  sender: {
    firstName: string;
    email: string;
    roles: string;
  };
}

interface Conversation {
  status: any;
  createdAt: string;
  id: string;
  name: string;
  picture: string;
  messages: Message[];
  users: Array<{
    email: string;
    roles: string;
  }>;
}

// Styled components
const MessageBubble = styled(Paper)(({ theme, isCustomer }: { theme?: any; isCustomer: boolean }) => ({
  padding: theme.spacing(2),
  maxWidth: '70%',
  marginBottom: theme.spacing(1),
  backgroundColor: isCustomer ? theme.palette.grey[100] : theme.palette.primary.main,
  color: isCustomer ? theme.palette.text.primary : theme.palette.primary.contrastText,
  alignSelf: isCustomer ? 'flex-start' : 'flex-end',
  borderRadius: 16,
  boxShadow: theme.shadows[1],
  '& .MuiTypography-root': {
    color: 'inherit',
  },
}));

const MessagesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  overflowY: 'auto',
  flex: 1,
});

const MessagesPage = () => {
  const theme = useTheme();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [messageContent, setMessageContent] = useState('');
  const [selectedType, setSelectedType] = useState<string | null>('allCategorie');
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [showConversations, setShowConversations] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessageTimestamp = useRef<string>('');

  const categories = [
    { name: "Toutes les catégories", value: "allCategorie", icon: <MessageIcon /> },
    { name: "Informations", value: "information", icon: <MessageIcon /> },
    { name: "Problème technique", value: "technique", icon: <BuildIcon /> },
    { name: "Autre", value: "autre", icon: <MoreIcon /> }
  ];

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Fetch conversations function
  const fetchConversations = useCallback(async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
        // Ajoutez d'autres headers si besoin
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/conversations`, {
        headers
      });
      const data = await response.json();
      setConversations(data.data || []);
      
      // Update filtered conversations if a type is selected
      if (selectedType !== 'allCategorie') {
        const filtered = (data.data || []).filter((conv: Conversation) => conv.name === selectedType);
        setFilteredConversations(filtered);
      } else {
        setFilteredConversations(data.data || []);
      }

      // Update selected conversation if one is active
      if (selectedConversation) {
        const updatedConversation = (data.data || []).find(
          (conv: Conversation) => conv.id === selectedConversation.id
        );
        if (updatedConversation) {
          const newMessages = updatedConversation.messages.filter(
            (message: any) => new Date(message.createdAt) > new Date(lastMessageTimestamp.current)
          );

          if (newMessages.length > 0) {
            setSelectedConversation(updatedConversation);
            lastMessageTimestamp.current = newMessages[newMessages.length - 1].createdAt;
            scrollToBottom();
          }
        }
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  }, [selectedType, selectedConversation, scrollToBottom]);

  // Initial fetch
  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation?.messages, scrollToBottom]);

  const handleTypeSelect = (event: SelectChangeEvent<string>) => {
    const type = event.target.value;
    setSelectedType(type);
    setSelectedConversation(null);
    const filtered = conversations.filter(conv => conv.name === type);
    setFilteredConversations(filtered);
    lastMessageTimestamp.current = '';
  };

  const handleConversationSelect = (conversation: Conversation) => {
    setSelectedConversation(conversation);
    setShowConversations(false);
    setShowSearch(false);
    if (conversation.messages.length > 0) {
      lastMessageTimestamp.current = conversation.messages[conversation.messages.length - 1].createdAt;
    }
  };

  const handleSendMessage = async () => {
    const senderId = localStorage.getItem('id');

    if (selectedConversation && messageContent && senderId) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/users/message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            content: messageContent,
            senderId,
            conversationId: selectedConversation.id,
          }),
        });

        // Clear message input immediately
        setMessageContent('');
        
        // Fetch updated conversations immediately after sending
        await fetchConversations();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
  
    const filtered = conversations.filter((conv) => {
      const email = conv.users.find((user) => user.roles === 'customer')?.email?.toLowerCase() || '';
      const date = DateTime.fromISO(conv.createdAt).toFormat('yyyy-MM-dd HH:mm:ss').toLowerCase();
      const statusText = conv.status ? 'resolu' : 'nouveau';
      const matchesSearch = email.includes(value) || date.includes(value) || statusText.includes(value);
      const matchesType = selectedType === 'allCategorie' ? true : conv.name === selectedType;
      return matchesSearch && matchesType;
    });
  
    setFilteredConversations(filtered);
  };

  const handleMarkAsResolved = async (conversationId: string, status: boolean) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/users/conversation/${conversationId}/status/${status}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      console.log('Conversation marked as resolved');

      // Fetch updated conversations immediately after marking as resolved
      await fetchConversations();
    } catch (error) {
      console.error('Error marking conversation as resolved:', error);
    }
  };

  const columns: any = useMemo(() => [
    { title: "Catégorie", field: "name", headerSort: false, responsive: 0, minWidth: 150, maxWidth: 200 },
    { title: "Date", field: "createdAt", formatter: (cell: any) => DateTime.fromISO(cell.getValue()).toFormat('yyyy-MM-dd HH:mm:ss'), headerSort: false, responsive: 0, minWidth: 150, maxWidth: 200 },
    { title: "Email", field: "users", formatter: (cell: any) => {
      const value = cell.getValue();
      if (Array.isArray(value)) {
        const user = value.find((user: any) => user.roles === 'customer');
        return user?.email || 'Utilisateur supprimé';
      }
      return '';
    }, 
    headerSort: false,
    responsive: 0,
    minWidth: 150,
    maxWidth: 200
    },
    { title: "Status", field: "status", 
      sorter: 'boolean', 
      headerSort: false,  
      formatter: (cell: any) => cell.getValue() ? '<span style="color: green;">Résolu</span>' : 'Nouveau',
      responsive: 0,
      minWidth: 150,
      maxWidth: 200
    },
    {
      title: "Action",
      field: "action",
      formatter: () =>  `<button class='edit-btn'>Voir Message</button> `,
      cellClick: (e: any, cell: any) => {
        const conversation = cell.getRow().getData();
        handleConversationSelect(conversation);
      },
      headerSort: false,
      responsive: 0,
      minWidth: 150,
      maxWidth: 200
    },
    {
      title: "Etat",
      field: "resolve",
      formatter: (cell: any) => {
        const conversation = cell.getRow().getData();
        const buttonText = conversation.status ? 'Réouvrir' : 'Marqué comme résolu';
        return `<button class='edit-btn'>${buttonText}</button>`;
      },
      cellClick: (e: any, cell: any) => {
        const conversation = cell.getRow().getData();
        const newStatus = !conversation.status;
        handleMarkAsResolved(conversation.id, newStatus);
      },
      headerSort: false,
      responsive: 0,
      minWidth: 150,
      maxWidth: 200
    },
  ], []);

  return (
    <Box>
      {/* Categories Dropdown */}
        {showSearch && (
          <Paper
            elevation={0}
            sx={{
              width: '70%',
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" sx={{ p: 2, fontWeight: 'medium' }}>
              Catégories
            </Typography>
            <FormControl fullWidth sx={{ p: 2 }}>
              <Select
                value={selectedType || ''}
                onChange={handleTypeSelect}
                label="Catégorie"
              >
                {categories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        )}

        {/* Search Field */}
        {showSearch && (
          <Paper
            elevation={0}
            sx={{
              width: '70%',
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Rechercher par email, date ou status ..."
                value={searchText}
                onChange={handleSearchChange}
                sx={{ mr: 2 }}
              />
              <IconButton onClick={fetchConversations}>
                <RefreshIcon />
              </IconButton>
            </Box>
          </Paper>
        )}
        {showConversations && (
          <Box sx={{ width: "100%", overflowX: "auto", padding: 0 }}>
            <Typography variant="h6" sx={{ p: 2, fontWeight: 'medium' }}>Conversations</Typography>
            <ReactTabulator
              data={filteredConversations}
              columns={columns}
              options={{
                layout: "fitDataTable",
                responsiveLayout: "collapse",
                pagination: "local",
                locale: "fr-fr",
                langs: {
                  "fr-fr": {
                    "pagination": {
                      "page_size": "Taille de page",
                      "first": "Premier",
                      "first_title": "Première Page",
                      "last": "Dernier",
                      "last_title": "Dernière Page",
                      "prev": "Précédent",
                      "prev_title": "Page Précédente",
                      "next": "Suivant",
                      "next_title": "Page Suivante",
                      "all": "Tous"
                    }
                  }
                },
                paginationSize: 10,
                paginationSizeSelector: [5, 10, 25],
                movableColumns: true,
                placeholder: "Aucun message trouvé",
                maxHeight: "50vh",
                initialSort: [
                  { column: "createdAt", dir: "desc" }
                ],
                rowFormatter: function (row: any) {
                  // Add custom CSS to rows
                  const element = row.getElement();
                  element.style.borderBottom = "1px solid #ddd";
                },
                virtualDom: true,
                renderVertical: 'basic', // Optimiser le rendu vertical
                renderHorizontal: 'virtual', // Optimiser le rendu horizontal
                resizableColumns: false, // Désactiver le redimensionnement des colonnes pour éviter les problèmes d'observateur de redimensionnement
                responsiveLayoutCollapseStartOpen: false,
                columnDefaults: {
                  resizable: false, // Désactiver le redimensionnement pour toutes les colonnes par défaut
                },
              }}
            />
          </Box>
        )}

        {/* Chat Area */}
        {selectedConversation && !showConversations && (
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                setShowConversations(true);
                setShowSearch(true);
              }}
              sx={{ m: 2 }}
            >
              Retour aux conversations
            </Button>
            {/* Messages */}
            <MessagesContainer>
              {selectedConversation.messages.map((message) => (
                <MessageBubble
                  key={message.id}
                  isCustomer={message.sender.roles === 'customer'}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {message.sender.firstName}
                  </Typography>
                  <Typography variant="body1">{message.content}</Typography>
                </MessageBubble>
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>

            {/* Message Input */}
            <Paper
              elevation={0}
              sx={{
                p: 2,
                borderTop: 1,
                borderColor: 'divider',
                bgcolor: 'background.paper',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  multiline  // Added multiline property
                  rows={2}  // Adjust the number of rows as needed
                  variant="outlined"
                  placeholder="Tapez votre message..."
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  onKeyPress={handleKeyPress}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                  }}
                />
                <IconButton
                  color="primary"
                  onClick={handleSendMessage}
                  disabled={!messageContent.trim()}
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
  );
};

export default MessagesPage;