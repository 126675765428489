import React, { useState, useEffect, useMemo, useRef } from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import './tabulatorCustomStyles.css'; // Importez le fichier CSS ici
import 'tabulator-tables/dist/css/tabulator.min.css';
import {
  Box,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useQueryProperty from '../queries/useQueryProperty';
import { SearchParams } from '../services/property';
import Loader from '../components/common/Loader';
window.addEventListener('error', (event) => {
  console.log(event.message);
  if (event.message.includes('ResizeObserver loop completed with undelivered notifications')) {
    event.preventDefault();
  }
});
const PropertyListing = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { getAllProperties } = useQueryProperty();
  const navigate = useNavigate();
  const variables: SearchParams = {};
  const resizeTimeoutRef = useRef<NodeJS.Timeout>();
  const tabulatorRef = useRef<any>(null);
  const { data: properties, error, isLoading } = getAllProperties(variables);

   // Handle resize events with debouncing
   useEffect(() => {
    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        if (tabulatorRef.current?.table) {
          tabulatorRef.current.table.redraw(true);
        }
      }, 250); // Debounce resize events
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    navigate('/property/add');
  };

  const handleEdit = (id: string) => {
    navigate(`/property/details/${id}`);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const columns: any = useMemo(() => [
    { title: 'Référence', field: 'reference', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0 },
    { title: 'Categorie', field: 'category', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0 },
    { title: 'Type de biens', field: 'type', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0 },
    { title: 'Noms', field: 'title', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0, editable: true, maxWidth: 200 },
    { title: 'Communes', field: 'commune.name', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0 },
    { title: 'Quartier', field: 'district.name', sorter: 'string', resizable: false, headerFilter: 'input', responsive: 0 },
    { title: 'Nbre pièces', field: 'rooms', sorter: 'number', headerFilter: 'input', responsive: 0 },
    { 
      title: 'Surface', 
      field: 'area', 
      sorter: 'number', resizable: false,
      headerFilter: 'input',responsive: 0,
      formatter: (cell: any) => `${cell.getValue()} m²`
    },
    { 
      title: 'Prix', 
      field: 'price', 
      sorter: 'number', resizable: false,
      headerFilter: 'input', responsive: 0,
      formatter: (cell: any) => `${cell.getValue()} €`
    },
    { 
      title: 'Contact', 
      field: 'agent', 
      resizable: false, 
      headerFilter: 'input',
      headerFilterFunc: (headerValue: string, rowValue: any) => {
        const fullName = `${rowValue?.firstName} ${rowValue?.lastName}`;
        return fullName.toLowerCase().includes(headerValue.toLowerCase());
      },
      formatter: (cell: any) => `${cell.getValue()?.firstName} ${cell.getValue()?.lastName}`,
      headerSort: false
    },
    { 
      title: 'Status', 
      field: 'published_at', 
      headerFilter: 'input',  responsive: 0,resizable: false,
      formatter: (cell: any) => cell.getValue() ? 'Publié' : 'Draft',
      formatterParams: {
        Draft: 'primary',
        Publié: 'success',
      }
    },
    { 
      title: 'Dispo', 
      field: 'available', 
      sorter: 'boolean', 
      headerFilter: 'input',resizable: false,
      formatter: 'tickCross', responsive: 0
    },
    {
      title: 'Action', responsive: 0,resizable: false,
      formatter: (cell: any) => `<button class="edit-btn">Voir</button>`,
      cellClick: (e: any, cell: any) => {
        handleEdit(cell.getRow().getData().id);
      },
      headerSort: false,
      width: 100,
    },
  ], []);

  const options = {
    layout: 'fitDataTable',
    responsiveLayout: 'collapse',
    pagination: 'local',
    locale:"fr-fr",
    langs:{
      "fr-fr": {
        "pagination": {
        "page_size": "Taille de page",
        "first": "Premier",
        "first_title": "Première Page",
        "last": "Dernier",
        "last_title": "Dernière Page",
        "prev": "Précédent",
        "prev_title": "Page Précédente",
        "next": "Suivant",
        "next_title": "Page Suivante",
        "all": "Tous"
        }
      }
    },
    paginationSize: 10,
    maxHeight: "100vh",
    paginationSizeSelector: [5, 10, 25, 50],
    movableColumns: true,
    initialSort: [{ column: 'reference', dir: 'asc' }],
    virtualDom: true,
    renderVertical: 'basic', // Optimize vertical rendering
    renderHorizontal: 'virtual', // Optimize horizontal rendering
    resizableColumns: false, // Disable column resizing to prevent resize observer issues
    responsiveLayoutCollapseStartOpen: false,
    columnDefaults: {
      resizable: false, // Disable resizing for all columns by default
    },
  };

    // Suppress ResizeObserver errors
    useEffect(() => {
      const errorHandler = (event: ErrorEvent) => {
        if (event.message.includes('ResizeObserver') || event.message.includes('Script error')) {
          event.stopPropagation();
          event.preventDefault();
        }
      };
  
      window.addEventListener('error', errorHandler);
      return () => window.removeEventListener('error', errorHandler);
    }, []);

  if (isLoading) return <Loader />;

  return (
    <>
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, color: '#3f51b5', textAlign: { xs: 'center', md: 'left' } }}>
        Les biens immobiliers
      </Typography>
      <ReactTabulator
        data={properties}
        columns={columns}
        options={options}
        data-custom-attr="test-custom-attribute"
        className="custom-css-class"
      />
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        sx={{ mt: 2, width: { xs: '100%', sm: 'auto' } }}
      >
        Ajouter un bien
      </Button>
    </Box>
    </>
    
  );
};

export default PropertyListing;