import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Email } from '@mui/icons-material';

const UserProfile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    id: '',
    phone: '',
    firstName: '',
    lastName: '',
    roles: '',
    email: '',
    avatar: '',
    createdAt: '',
    updatedAt: '',
    confirmed: false,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setUserData(data.data);
        if (data.data.avatar) {
          setImagePreview(`${process.env.REACT_APP_API_URL}/files/avatars/${data.data.avatar}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          email: userData.email,
          roles: userData.roles,
        }),
      });

      if (imageFile) {
        const formData = new FormData();
        formData.append('file', imageFile);

        await fetch(`${process.env.REACT_APP_API_URL}/users/${id}/avatar`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        });
      }

      setSnackbarMessage('Profile modifié avec succès');
      setSnackbarSeverity('success');
      navigate('/agents');
    } catch (error) {
      setSnackbarMessage('Error updating profile: ' + error);
      setSnackbarSeverity('error');
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 500, mx: 'auto', textAlign: 'center' }}>
      <Typography variant="h5" sx={{ mb: 2, color: '#3f51b5' }}>
        Modifier le profil
      </Typography>
      <Box component="form" onSubmit={handleFormSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            id="profile-picture-upload"
            onChange={handleImageChange}
          />
          <label htmlFor="profile-picture-upload">
            <Avatar
              src={imagePreview || undefined}
              sx={{ width: 100, height: 100, cursor: 'pointer' }}
            />
          </label>
        </Box>
        <TextField
          fullWidth
          label="Prénom"
          name="firstName"
          value={userData.firstName}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          fullWidth
          label="Nom"
          name="lastName"
          value={userData.lastName}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          required
        />
        <PhoneInput
          country={'cd'} // RDC (Congo) country code
          value={userData.phone}
          onChange={(phone) => setUserData((prevData) => ({ ...prevData, phone }))}
          inputStyle={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
          containerStyle={{ width: '100%' }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          onChange={handleInputChange}
          value={userData.email}
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Rôle"
          name="roles"
          select
          value={userData.roles}
          onChange={handleInputChange}
          margin="normal"
          variant="outlined"
          required
        >
          <MenuItem value="superadmin">Superadmin</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="agent">Agent</MenuItem>
        </TextField>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Mettre à jour le profil'}
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;