import { AxiosResponse } from "axios";
import { SearchParams } from "./property";
import api from "./api";
import { Agent, CreateUserInput } from "../types/property.types";

export const getUsers = async (searchParams?: SearchParams): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get("/users", {
      params: searchParams,
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getUser = async (id: string): Promise<Agent> => {
  try {
    const response: AxiosResponse = await api.get(`/users/${id}`);

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (user: CreateUserInput): Promise<Agent> => {
  try {
    const response: AxiosResponse = await api.post("/users/agent", user);

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
