import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createUser } from "../services/user";
import {  CreateUserInput } from "../types/property.types";



export const useCreateUserMutation = () => {
  const navigate = useNavigate();

  return useMutation<any, Error, CreateUserInput>({
    mutationFn: createUser,
    onSuccess: (data) => {
      navigate("/agents");
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
  });
};


