import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  Typography,
  Paper,
  Grid,
  SelectChangeEvent,
  Autocomplete,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ImagePlus, Trash2, Upload, X } from "lucide-react";
import useQueryProperty from "../queries/useQueryProperty";

// Constants
const PROPERTY_TYPES = [
  "Appartement",
  "Villa",
  "Immeuble",
  "Entrepot",
  "Chambre",
  "Studio",
  "BatimentCommercial",
  "Parcelle",
] as const;

const CATEGORIES = ["Location", "Achat"] as const;

const CHARACTERISTICS = [
  "Balcon",
  "Jardin",
  "Piscine",
  "Garage",
  "Cheminée",
  "Terrasse",
  "Climatisation",
  "Panneaux Solaires",
  "Système de Sécurité",
  "Ascenseur",
  "Salle de Sport",
  "Aire de Jeux",
  "Sauna",
  "Jacuzzi",
  "Sous-sol",
  "Bureau à Domicile",
  "Dressing",
  "Buanderie",
  "Cuisine Extérieure",
  "Toit-terrasse",
  "Cave à Vin",
  "Fonctionnalités Domotiques",
] as const;

interface PropertyData {
  type: (typeof PROPERTY_TYPES)[number];
  category: (typeof CATEGORIES)[number];
  price: number;
  area: number;
  rooms: number;
  bathRoom: number;
  bedRoom?: number;
  parking: number;
  description: string;
  characteristic: string[];
  city: string;
  street: string;
  commune: string; // Add the 'commune' property
  district: string;
  number: string;
  title: string;
  agentId?: string;
  mainImage?: string;
  photos?: string[];
}

interface PhotoInput {
  id: string;
  file: File | null;
  preview: string;
  error: string;
}

interface Agent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Commune {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  quartiers: Quartier[];
}

interface Quartier {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  communeId: number;
}

interface ValidationErrors {
  [key: string]: string;
}

const UpdateProperty = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [existingPhotos, setExistingPhotos] = useState<string[]>([]);
  const [mainImage, setMainImage] = useState<string>("");
  const [photoInputs, setPhotoInputs] = useState<PhotoInput[]>([]);
  const [photosToDelete, setPhotosToDelete] = useState<string[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState<string>("");
  const [existingPhotoNames, setExistingPhotoNames] = useState<string[]>([]); // Pour stocker les noms originaux des fichiers
  const { useCommunes, useAgents } = useQueryProperty();
  const { data: communes } = useCommunes();
  const [selectedCommune, setSelectedCommune] = useState<Commune | null>(null);
  const [selectedQuartier, setSelectedQuartier] = useState<Quartier | null>(
    null
  );

  const maxFileSize = 1024 * 1024; // 1MB
  const [formData, setFormData] = useState<PropertyData>({
    type: "Appartement",
    category: "Location",
    price: 0,
    area: 0,
    rooms: 0,
    bathRoom: 0,
    parking: 0,
    description: "",
    characteristic: [],
    district: "",
    city: "",
    street: "",
    number: "",
    title: "",
    commune: "", // Add the 'commune' property
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users?where=roles%3Aagent`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          setAgents(data.data);
        }
      } catch (err) {
        console.error("Error fetching agents:", err);
      }
    };

    fetchAgents();
  }, []);
  // Fetch property data
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/property/${id}`
        );
        const result = await response.json();

        if (result.success) {
          const { data } = result;
          console.log(data);
          setFormData({
            type: data.type,
            category: data.category,
            price: Number(data.price),
            area: Number(data.area),
            rooms: data.rooms,
            bathRoom: data.bathRoom,
            parking: data.parking,
            description: data.description,
            characteristic: data.characteristic,
            city: data.city,
            commune: data.commune?.name, // Add the 'commune' property
            district: data.district?.name,
            street: data.street,
            number: data.number,
            title: data.title,
            agentId: data.agentId,
          });
          setExistingPhotoNames(data.photos || []);
          setMainImage(
            data.photos[0]
              ? `${process.env.REACT_APP_API_URL}/files/uploads/${data.photos[0]}`
              : ""
          );
          setExistingPhotos(
            data.photos.map(
              (e: string) =>
                `${process.env.REACT_APP_API_URL}/files/uploads/${e}`
            ) || []
          );

          const propertyAgent = agents.find(
            (agent) => agent.id === data.agentId
          );
          setSelectedAgent(propertyAgent || null);

          // Find and set the selected commune and quartier
          const selectedCommune =
            communes?.find(
              (commune: { name: any }) => commune.name === data.commune?.name
            ) || null;
          setSelectedCommune(selectedCommune);

          const selectedQuartier =
            selectedCommune?.quartiers.find(
              (quartier: { name: any }) => quartier.name === data.district?.name
            ) || null;
          setSelectedQuartier(selectedQuartier);
        } else {
          setError("Failed to load property data");
        }
      } catch (err) {
        setError("Error loading property data");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchPropertyData();
    }
  }, [id, agents, communes]);

  // Effect to refresh quartiers when selectedCommune changes
  useEffect(() => {
    if (selectedCommune) {
      setSelectedQuartier(null);
      setFormData((prev) => ({
        ...prev,
        district: "",
      }));
    }
  }, [selectedCommune]);

  const validatePhotoInput = (file: File): string => {
    if (file.size > maxFileSize) {
      return "L'image doit être inférieure à 1Mo";
    }
    if (!file.type.startsWith("image/")) {
      return "Seuls les fichiers image sont acceptés";
    }
    return "";
  };

  const handlePhotoAdd = () => {
    setPhotoInputs((prev) => [
      ...prev,
      {
        id: crypto.randomUUID(),
        file: null,
        preview: "",
        error: "",
      },
    ]);
  };

  const handlePhotoRemove = (id: string) => {
    setPhotoInputs((prev) => prev.filter((input) => input.id !== id));
  };

  const handlePhotoChange = (id: string, file: File | null) => {
    setPhotoInputs((prev) =>
      prev.map((input) => {
        if (input.id === id) {
          const error = file ? validatePhotoInput(file) : "";
          const preview = file && !error ? URL.createObjectURL(file) : "";
          return {
            ...input,
            file,
            preview,
            error,
          };
        }
        return input;
      })
    );
  };

  const handleExistingPhotoDelete = (photoUrl: string) => {
    setPhotoToDelete(photoUrl);
    setShowDeleteDialog(true);
  };

  // Modifiez la fonction confirmPhotoDelete pour gérer les noms de fichiers
  const confirmPhotoDelete = () => {
    setPhotosToDelete((prev) => [...prev, photoToDelete]);
    setExistingPhotos((prev) =>
      prev.filter((photo) => photo !== photoToDelete)
    );
    setShowDeleteDialog(false);
  };

  const validateForm = (): boolean => {
    const errors: ValidationErrors = {};

    if (!formData.title.trim()) {
      errors.title = "Title is required";
    }
    if (formData.price <= 0) {
      errors.price = "Price must be greater than 0";
    }
    if (formData.area <= 0) {
      errors.area = "Area must be greater than 0";
    }
    if (formData.rooms < 0) {
      errors.rooms = "Rooms cannot be negative";
    }
    if (!formData.city.trim()) {
      errors.city = "City is required";
    }
    if (!formData.street.trim()) {
      errors.street = "Street is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const propertyData = {
        type: formData.type,
        category: formData.category,
        price: formData.price,
        description: formData.description,
        area: formData.area,
        rooms: formData.rooms,
        bedRoom: formData.bedRoom,
        bathRoom: formData.bathRoom,
        title: formData.title,
        parking: formData.parking,
        characteristic: formData.characteristic,
        city: formData.city,
        street: formData.street,
        number: formData.number,
        commune: {
          connect: {
            id: selectedCommune?.id
          }
        },
        district: {
          connect: {
            id: selectedQuartier?.id
          }
        },
        agent: {
          connect: {
            id: selectedAgent?.id
          }
        }
      };

      const responseD = await fetch(
        `${process.env.REACT_APP_API_URL}/property/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...propertyData,
            // agentId: selectedAgent?.id,
          }),
        }
      );
      const formDatas = new FormData();
      // Ajouter les nouvelles photos
      photoInputs.forEach((input) => {
        if (input.file && !input.error) {
          formDatas.append("photos", input.file);
        }
      });

      // Ajouter la liste des photos à supprimer
      if (photosToDelete.length > 0) {
        formDatas.append("photosToDelete", JSON.stringify(photosToDelete));
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/property/${id}`,
        {
          method: "PATCH",
          body: formDatas,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update property");
      }

      navigate("/property");
    } catch (err) {
      // setError(err.message || 'Error updating property');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "price" ||
          name === "area" ||
          name === "rooms" ||
          name === "bedRoom" ||
          name === "bathRoom" ||
          name === "parking"
          ? Number(value)
          : value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCharacteristicChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      characteristic: typeof value === "string" ? value.split(",") : value,
    }));
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box component={Paper} sx={{ p: 3, maxWidth: 1800, mx: "auto", mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Modifier la propriété
      </Typography>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!validationErrors.type}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleSelectChange}
                label="Type"
              >
                {PROPERTY_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!validationErrors.category}>
              <InputLabel>Catégorie</InputLabel>
              <Select
                name="category"
                value={formData.category}
                onChange={handleSelectChange}
                label="Catégorie"
              >
                {CATEGORIES.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Prix"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleChange}
              error={!!validationErrors.price}
              helperText={validationErrors.price}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Surface (m²)"
              name="area"
              type="number"
              value={formData.area}
              onChange={handleChange}
              error={!!validationErrors.area}
              helperText={validationErrors.area}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Pieces"
              name="rooms"
              type="number"
              value={formData.rooms}
              onChange={handleChange}
              error={!!validationErrors.rooms}
              helperText={validationErrors.rooms}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Chambres"
              name="bedRoom"
              type="number"
              value={formData.bedRoom}
              onChange={handleChange}
              error={!!validationErrors.bedRoom}
              helperText={validationErrors.bedRoom}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Salles de bain"
              name="bathRoom"
              type="number"
              value={formData.bathRoom}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Places de parking"
              name="parking"
              type="number"
              value={formData.parking}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Titre"
              name="title"
              value={formData.title}
              onChange={handleChange}
              error={!!validationErrors.title}
              helperText={validationErrors.title}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Caractéristiques</InputLabel>
              <Select
                multiple
                name="characteristic"
                value={formData.characteristic}
                onChange={handleCharacteristicChange}
                input={<OutlinedInput label="Caractéristiques" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {CHARACTERISTICS.map((characteristic) => (
                  <MenuItem key={characteristic} value={characteristic}>
                    <Checkbox
                      checked={
                        formData.characteristic.indexOf(characteristic) > -1
                      }
                    />
                    <ListItemText primary={characteristic} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Ville"
              name="city"
              value={formData.city}
              onChange={handleChange}
              error={!!validationErrors.city}
              helperText={validationErrors.city}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Rue"
              name="street"
              value={formData.street}
              onChange={handleChange}
              error={!!validationErrors.street}
              helperText={validationErrors.street}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Numéro"
              name="number"
              value={formData.number}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={selectedCommune}
              onChange={(event: any, newValue: Commune | null) => {
                setSelectedCommune(newValue);
                setFormData((prev) => ({
                  ...prev,
                  commune: newValue ? newValue.name : "",
                }));
              }}
              options={communes || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Commune"
                  error={!!validationErrors.commune}
                  helperText={validationErrors.commune}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={selectedQuartier}
              onChange={(event: any, newValue: Quartier | null) => {
                setSelectedQuartier(newValue);
                setFormData((prev) => ({
                  ...prev,
                  district: newValue ? newValue.name : "",
                }));
              }}
              options={selectedCommune ? selectedCommune.quartiers : []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Quartier"
                  error={!!validationErrors.district}
                  helperText={validationErrors.district}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                value={selectedAgent}
                onChange={(event: any, newValue: any) =>
                  setSelectedAgent(newValue)
                }
                options={agents}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName} (${option.email})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agent Assigné"
                    error={!!validationErrors.agent}
                    helperText={validationErrors.agent}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Box sx={{ mt: 6, mx: 2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: "1.5rem", mb: 3 }}
            >
              Photos existantes
            </Typography>

            <Grid container spacing={4}>
              {existingPhotos.map((photo, index) => (
                <Grid item xs={12} key={photo}>
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "56.25%", // 16:9 aspect ratio
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundColor: "background.paper",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                      "&:hover": {
                        boxShadow: "0 6px 16px rgba(0,0,0,0.2)",
                        transform: "translateY(-2px)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    <img
                      src={photo}
                      alt={`Property ${index + 1}`}
                      style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: 2,
                        background:
                          "linear-gradient(45deg, transparent, rgba(0,0,0,0.1))",
                        borderRadius: "0 0 0 16px",
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.95)",
                          boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                          "&:hover": {
                            backgroundColor: "error.main",
                            color: "white",
                          },
                          transition: "all 0.2s ease-in-out",
                          padding: 1.5,
                        }}
                        onClick={() => handleExistingPhotoDelete(photo)}
                      >
                        <Trash2 className="w-6 h-6" />
                      </IconButton>
                    </Box>
                    {index === 0 && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left: 16,
                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                          color: "white",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          fontSize: "0.875rem",
                        }}
                      >
                        Photo principale
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ mt: 6, mx: 2 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: "1.5rem", mb: 3 }}
            >
              Ajouter de nouvelles photos
            </Typography>

            <Grid container spacing={3}>
              {photoInputs.map((input) => (
                <Grid item xs={12} md={6} key={input.id}>
                  <Box
                    sx={{
                      border: "2px dashed",
                      borderColor: input.error ? "error.main" : "primary.main",
                      borderRadius: 2,
                      position: "relative",
                      height: "400px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "background.paper",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        borderColor: "primary.dark",
                        backgroundColor: "action.hover",
                      },
                      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handlePhotoChange(input.id, e.target.files?.[0] || null)
                      }
                      style={{ display: "none" }}
                      id={`photo-input-${input.id}`}
                    />

                    {input.preview ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={input.preview}
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 16,
                            right: 16,
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            "&:hover": { backgroundColor: "action.hover" },
                          }}
                          onClick={() => handlePhotoChange(input.id, null)}
                        >
                          <X className="w-5 h-5" />
                        </IconButton>
                      </Box>
                    ) : (
                      <label htmlFor={`photo-input-${input.id}`}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                            p: 4,
                          }}
                        >
                          <Upload className="w-16 h-16 mb-4" />
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            align="center"
                            sx={{ mb: 2 }}
                          >
                            Cliquez ou glissez une image ici
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            Max: 1Mo
                          </Typography>
                        </Box>
                      </label>
                    )}

                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        backgroundColor: "error.main",
                        color: "white",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                        "&:hover": { backgroundColor: "error.dark" },
                      }}
                      onClick={() => handlePhotoRemove(input.id)}
                    >
                      <X className="w-5 h-5" />
                    </IconButton>

                    {input.error && (
                      <Typography
                        color="error"
                        variant="body1"
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          textAlign: "center",
                        }}
                      >
                        {input.error}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}

              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    height: "400px",
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    "&:hover": {
                      backgroundColor: "action.hover",
                    },
                  }}
                  onClick={handlePhotoAdd}
                >
                  <ImagePlus className="w-16 h-16" />
                  <Typography variant="h6">Ajouter une photo</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                onClick={() => navigate("/property")}
                disabled={isLoading}
              >
                Annuler
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading
                  ? "Mise à jour en cours..."
                  : "Mettre à jour la propriété"}
              </Button>
            </Box>
          </Grid>

          <Dialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
          >
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <Typography>
                Êtes-vous sûr de vouloir supprimer cette photo ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDeleteDialog(false)}>
                Annuler
              </Button>
              <Button onClick={confirmPhotoDelete} color="error">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateProperty;
